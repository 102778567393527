<script>
import find from 'lodash/find';
import get from 'lodash/get';
import { mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TELEMETRY_PDF_ID } from '../const/telemetry.const';

import TelemetrySummary from './TelemetrySummary.vue';
import TelemetryCharts from './TelemetryCharts.vue';

export default {
  name: 'TelemetryPdf',
  components: {
    TelemetrySummary,
    TelemetryCharts,
  },
  props: {
    queryDates: {
      type: Object,
      default: () => ({}),
    },
    selectedTelemetry: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.vehicle.summary, {
      summaryStatus: state => state.summary.STATUS,
      summaryError: state => get(state, 'summary.error') || {},
      summaryComponents: state => get(state, 'summary.data.components') || {},
    }),
    vehicleData() {
      const vehicleComponent = find(this.summaryComponents, { type: 'vehicleComponent' }) || {};
      return get(vehicleComponent, 'data', {});
    },
  },
  created() {
    this.TELEMETRY_PDF_ID = TELEMETRY_PDF_ID;
  },
  methods: {
    get,
  },
};
</script>

<template>
  <div
    :id="TELEMETRY_PDF_ID"
    class="TelemetryPdf emobg-background-color-white p-4"
  >
    <div class="d-flex justify-content-between align-items-center pb-4 mb-4 emobg-border-bottom-1 emobg-border-color-ground-light">
      <img
        src="/carrental/logo.svg"
        width="100px"
        height="50px"
        alt="logo"
      >
      <div class="d-flex flex-column">
        <h2 class="mb-2">
          License plate: {{ get(vehicleData,'license_plate', FALLBACK_MESSAGE.notAvailable) }}
        </h2>
        <div class="emobg-font-small">
          Model: {{ get(vehicleData,'model', FALLBACK_MESSAGE.notAvailable) }}
        </div>
      </div>
    </div>
    <ui-card
      header="Telemetry report"
      data-test-id="summary-card"
      class="noBorderCard d-block"
    >
      <TelemetrySummary
        :query-dates="queryDates"
        :selected-telemetry="selectedTelemetry"
        is-for-pdf
        data-test-id="telemetry_summary-component"
      />
    </ui-card>
    <TelemetryCharts
      :selected-telemetry="selectedTelemetry"
      class="mb-3"
      is-for-pdf
    />
    <div class="emobg-font-x-small emobg-color-ink">
      <p>
        Please note that your personal data has been collected by Europcar through your rental journey. For more information about these processing activities, you can consult our dedicated privacy policy on connected vehicles: https://www.europcar.com/files/live/sites/erc/files/connected-cars/privacy-policy.pdf
      </p>
      <br>
      <p>
        In accordance with the applicable laws and regulations, you have the right to access, to rectify, to erase, to object, to data portability, or to request the limitation of the processing of your personal data. You can exercise these rights at any time by sending an email to the following email address dpo@europcar.com or by contacting our customer service department.
      </p>
    </div>
  </div>
</template>
