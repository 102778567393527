<script>
import carrental from '../router/CarrentalRouterMap';

export default {
  name: 'StationLink',
  props: {
    stationUuid: {
      type: String,
      default: '',
    },
    stationId: {
      type: String,
      default: '',
    },
  },
  created() {
    this.carrental = carrental;
  },
};
</script>

<template>
  <div v-if="stationId && stationUuid">
    <RouterLink
      :to="{
        name: carrental.vehicles.stations.details,
        params: { stationUuid },
      }"
      :data-test-id="`station-link-${stationUuid}`"
      class="emobg-link-primary emobg-body-2"
    >
      {{ stationId }}
    </RouterLink>
  </div>
  <div v-else>
    {{ FALLBACK_MESSAGE.dash }}
  </div>
</template>
