export const DESCRIPTION_INFO_KEYS = [
  'licensePlate',
  'model',
  'category',
  'colour',
  'vin',
  'vehicleType',
  'fuelType',
  'vendorId',
  'saleFlag',
];
export const FINANCIAL_INFO_KEYS = ['contractEndDate', 'contractEndMileage', 'financingMode'];

export const PROPERTY_TO_LABEL_TEXT = {
  vin: 'Vin number',
  licensePlate: 'Registration number',
};
