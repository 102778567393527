var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "RechabilityCell mb-3" }, [
    _c(
      "h3",
      {
        staticClass: "mb-1",
        attrs: { "data-test-id": "data_last_received-label" },
      },
      [_vm._v(" Data last received ")]
    ),
    _c(
      "div",
      { staticClass: "info-box mb-1" },
      [
        _c("ui-icon", {
          staticClass: "mr-2 d-inline-block emobg-color-black",
          attrs: { size: _vm.ICONS_SIZES.large, icon: _vm.ICONS.connectivity },
        }),
        _c(
          "div",
          {
            staticClass: "d-flex emobg-body-1",
            attrs: { "data-test-id": "data_last_received-value" },
          },
          [_vm._v(" " + _vm._s(_vm.reachabilityInfo) + " ")]
        ),
      ],
      1
    ),
    _c(
      "span",
      {
        staticClass:
          "emobg-font-small emobg-font-weight-normal emobg-color-ink-light",
        attrs: { "data-test-id": "data_last_received-timestamp" },
      },
      [
        _vm._v(
          " Last received: " +
            _vm._s(
              _vm.timeStamp
                ? _vm.formatUtc(
                    _vm.timeStamp,
                    _vm.DATE_FORMAT.defaultExtended,
                    _vm.operatorTimezone
                  )
                : _vm.FALLBACK_MESSAGE.noData
            ) +
            " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }