var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Filters w-100 col pt-3 px-1" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          class: `${_vm.isMobileLayout ? "col-md-12 col-lg-2" : "col-12 mb-3"}`,
        },
        [
          _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
            _vm._v(" Type "),
          ]),
          _c("ui-select-multiple", {
            staticClass: "w-100",
            attrs: {
              "data-test-id": "movement_type-select",
              placeholder: "Select movement type",
              name: "types",
            },
            domProps: { values: _vm.types, options: _vm.movementTypesOptions },
            on: {
              selectoptions: ({ detail }) =>
                _vm.onFilterChanged(detail, _vm.FILTER_KEYS.types),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: `${_vm.isMobileLayout ? "col-md-12 col-lg-5" : "col-12 mb-3"}`,
        },
        [
          _c("div", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { class: `${_vm.isMobileLayout ? "col-4" : "col-12 mb-3"}` },
              [
                _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
                  _vm._v(" Check-out station "),
                ]),
                _c("ui-select-multiple", {
                  staticClass: "w-100 hydrated",
                  class: { "Filters--noBorderRight": _vm.isMobileLayout },
                  attrs: {
                    "data-test-id": "start_station-select",
                    placeholder: "Station",
                    name: "checkout station",
                  },
                  domProps: {
                    values: _vm.startLocationIds,
                    options: _vm.startLocationIdsOptions,
                  },
                  on: {
                    selectoptions: ({ detail }) =>
                      _vm.onFilterChanged(
                        detail,
                        _vm.FILTER_KEYS.startLocationIds
                      ),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: `${_vm.isMobileLayout ? "col-4" : "col-12 mb-3"}` },
              [
                _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isMobileLayout ? "From" : "Check-out From") +
                      " "
                  ),
                ]),
                _c("ui-datetimepicker", {
                  staticClass: "w-100 hydrated",
                  class: {
                    "Filters--noBorderRight Filters--noBorderRadiusLeft":
                      _vm.isMobileLayout,
                  },
                  attrs: {
                    size: _vm.SIZES.small,
                    "data-test-id": "start_location_from-datepicker",
                  },
                  domProps: { date: _vm.startLocationFromDate },
                  on: {
                    datechanged: ({ detail }) =>
                      _vm.onDateChanged(
                        detail,
                        _vm.FILTER_KEYS.startLocationFromDate
                      ),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: `${_vm.isMobileLayout ? "col-4" : "col-12"}` },
              [
                _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isMobileLayout ? "To" : "Check-out To") +
                      " "
                  ),
                ]),
                _c("ui-datetimepicker", {
                  staticClass: "w-100 hydrated",
                  class: { "Filters--noBorderRadiusLeft": _vm.isMobileLayout },
                  attrs: {
                    size: _vm.SIZES.small,
                    "data-test-id": "start_location_to-datepicker",
                  },
                  domProps: { date: _vm.startLocationToDate },
                  on: {
                    datechanged: ({ detail }) =>
                      _vm.onDateChanged(
                        detail,
                        _vm.FILTER_KEYS.startLocationToDate
                      ),
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { class: `${_vm.isMobileLayout ? "col-md-12 col-lg-5" : "col-12"}` },
        [
          _c("div", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { class: `${_vm.isMobileLayout ? "col-4" : "col-12 mb-3"}` },
              [
                _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
                  _vm._v(" Check-in station "),
                ]),
                _c("ui-select-multiple", {
                  staticClass: "w-100 hydrated",
                  class: { "Filters--noBorderRight": _vm.isMobileLayout },
                  attrs: {
                    "data-test-id": "end_station-select",
                    placeholder: "Station",
                    name: "stations",
                  },
                  domProps: {
                    values: _vm.endLocationIds,
                    options: _vm.endLocationIdsOptions,
                  },
                  on: {
                    selectoptions: ({ detail }) =>
                      _vm.onFilterChanged(
                        detail,
                        _vm.FILTER_KEYS.endLocationIds
                      ),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: `${_vm.isMobileLayout ? "col-4" : "col-12 mb-3"}` },
              [
                _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isMobileLayout ? "From" : "Check-in From") +
                      " "
                  ),
                ]),
                _c("ui-datetimepicker", {
                  staticClass: "w-100 hydrated",
                  class: {
                    "Filters--noBorderRight Filters--noBorderRadiusLeft":
                      _vm.isMobileLayout,
                  },
                  attrs: {
                    size: _vm.SIZES.small,
                    "data-test-id": "end_location_from-datepicker",
                  },
                  domProps: { date: _vm.endLocationFromDate },
                  on: {
                    datechanged: ({ detail }) =>
                      _vm.onDateChanged(
                        detail,
                        _vm.FILTER_KEYS.endLocationFromDate
                      ),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: `${_vm.isMobileLayout ? "col-4" : "col-12"}` },
              [
                _c("p", { staticClass: "emobg-body-2 mb-1 w-100" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isMobileLayout ? "To" : "Check-in To") +
                      " "
                  ),
                ]),
                _c("ui-datetimepicker", {
                  staticClass: "w-100 hydrated",
                  class: { "Filters--noBorderRadiusLeft": _vm.isMobileLayout },
                  attrs: {
                    size: _vm.SIZES.small,
                    "data-test-id": "end_location_to-datepicker",
                  },
                  domProps: { date: _vm.endLocationToDate },
                  on: {
                    datechanged: ({ detail }) =>
                      _vm.onDateChanged(
                        detail,
                        _vm.FILTER_KEYS.endLocationToDate
                      ),
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }