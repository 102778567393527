var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "VehiclesSummaryView",
      attrs: { "data-test-id": "vehicles_summary-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-block pt-3 emobg-background-color-white" },
        [
          _vm.includes(
            _vm.permissions,
            _vm.CARRENTAL_PERMISSIONS.vehicleViewDetailsHeader
          )
            ? _c("VehicleSummaryHeaderComponent", {
                staticClass: "mt-2 px-lg-5",
                attrs: {
                  status: _vm.summaryStatus,
                  data: _vm.summaryData,
                  "data-test-id": "vehicle_summary_header",
                },
              })
            : _vm._e(),
          _vm.tabs.length
            ? _c("MuiTabs", {
                staticClass: "mt-1 mb-n3 pl-5",
                attrs: { tabs: _vm.tabs, active: _vm.activeTab },
                on: { "active-tab": _vm.updateRoute },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pt-4 px-2 mt-3 px-lg-5" },
        [_c("Transition", { attrs: { name: "page" } }, [_c("RouterView")], 1)],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }