<script>
import some from 'lodash/some';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import includes from 'lodash/includes';
import { mapState } from 'vuex';

import { formatUtc, sentenceCase, TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { mileageUnitMixin } from '../../../../mixins';
import { DESCRIPTION_INFO_KEYS, PROPERTY_TO_LABEL_TEXT } from '../constants/vehicleInfo.const';

export default {
  name: 'VehicleDescriptionCard',
  mixins: [mileageUnitMixin],
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    hasInfo() {
      return some(this.info);
    },
  },
  created() {
    this.PROPERTY_TO_LABEL_TEXT = PROPERTY_TO_LABEL_TEXT;
    this.DESCRIPTION_INFO_KEYS = DESCRIPTION_INFO_KEYS;
    this.headerText = 'Vehicle description';
  },
  methods: {
    snakeCase,
    includes,
    sentenceCase,
    formatUtc,
  },
};
</script>

<template>
  <ui-card
    v-if="hasInfo"
    :header="headerText"
    :data-test-id="`${snakeCase(headerText)}-card`"
    rounded
    class="mb-4 d-block"
  >
    <div
      v-if="info"
      class="row"
    >
      <div
        v-for="(key) in DESCRIPTION_INFO_KEYS"
        :key="key"
        class="col-6 col-xl-3 mb-5"
      >
        <div
          class="mb-1 emobg-font-weight-bold"
          :data-test-id="`${snakeCase(key)}-label`"
        >
          {{ PROPERTY_TO_LABEL_TEXT[key] || sentenceCase(key) }}
        </div>
        <span :data-test-id="`${snakeCase(key)}-value`">{{ info[key] || FALLBACK_MESSAGE.notAvailable }}</span>
      </div>
    </div>
    <div
      v-else
      data-test-id="no_description_info_data-label"
    >
      {{ FALLBACK_MESSAGE.noData }}
    </div>
  </ui-card>
</template>
