<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';

import { mapActions, mapState } from 'vuex';
import { MuiApiList } from '@emobg/vue-internal';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { contentCells } from './config/movementsContentCells';
import { MOVEMENTS_ITEMS_PER_PAGE } from './const/movements.const';

import Filters from './components/FiltersComponent.vue';

export default {
  name: 'SummaryMovementsView',
  components: {
    MuiApiList,
    Filters,
  },
  data() {
    return {
      pageNumber: 0,
      isMobileLayout: true,
      filters: {
        types: [],
        startLocationIds: [],
        endLocationIds: [],
        startLocationFromDate: '',
        startLocationToDate: '',
        endLocationFromDate: '',
        endLocationToDate: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.vehicle.summary, {
      isLoading: state => state.movements.STATUS.LOADING || state.movementsFilters.STATUS.LOADING,
      movementsList: state => get(state, 'movementsList.data.movements') || [],
      totalPages: state => get(state, 'movementsList.data.pageCount') || 1,
    }),
    queryData() {
      return {
        pageNumber: this.pageNumber,
        pageSize: MOVEMENTS_ITEMS_PER_PAGE,
        ...omitBy(this.filters, isEmpty),
      };
    },
    activeFiltersCount() {
      const activeFilters = filter(this.filters, filterValue => !isEmpty(filterValue));
      return reduce(activeFilters, (filtersCount, filterValue) => {
        const count = isArray(filterValue) ? filterValue.length : 1;
        return filtersCount + count;
      }, 0);
    },
  },
  watch: {
    pageNumber: {
      handler() {
        this.getVehicleMovementsPaginated({
          vehicleVin: this.vehicleVin,
          query: this.queryData,
        });
      },
      immediate: true,
    },
    filters: {
      handler() {
        if (!this.pageNumber) {
          this.getVehicleMovementsPaginated({
            vehicleVin: this.vehicleVin,
            query: this.queryData,
          });
        } else {
          this.pageNumber = 0;
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    this.vehicleVin = this.$route.params.vehicleVin;
    this.contentCells = contentCells;
    this.MOVEMENTS_ITEMS_PER_PAGE = MOVEMENTS_ITEMS_PER_PAGE;
  },
  mounted() {
    this.getVehicleMovementsFilters(this.vehicleVin);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.vehicle.summary, [
      'getVehicleMovementsPaginated',
      'getVehicleMovementsFilters',
    ]),
    resetFilters() {
      this.filters.types = [];
      this.filters.startLocationIds = [];
      this.filters.endLocationIds = [];
      this.filters.startLocationFromDate = '';
      this.filters.startLocationToDate = '';
      this.filters.endLocationFromDate = '';
      this.filters.endLocationToDate = '';
    },
  },
};
</script>

<template>
  <div
    class="SummaryMovementsView emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small px-3 py-4"
    data-test-id="summary_movements-view"
  >
    <h1 class="pb-3 mb-2 emobg-border-bottom-2 emobg-border-color-ground-light">
      Movements
    </h1>
    <MuiApiList
      :data-set="movementsList"
      :content-cells="contentCells"
      :is-loading="isLoading"
      :active-filters-count="activeFiltersCount"
      :external-pagination="true"
      :page-size="MOVEMENTS_ITEMS_PER_PAGE"
      :total-records="(totalPages * MOVEMENTS_ITEMS_PER_PAGE)"
      :no-data-label="FALLBACK_MESSAGE.noData"
      :no-border="true"
      data-test-id="list"
      @update:page="(page) => pageNumber = page.currentPage"
      @update:isMobileLayout="(isMobile) => isMobileLayout = isMobile"
      @update:clearFilters="resetFilters"
    >
      <Filters
        slot="customFilters"
        :is-mobile-layout="isMobileLayout"
        :clear="!activeFiltersCount"
        @on:filterChanged="({ filterData, filterKey }) => filters[filterKey] = filterData"
      />
    </MuiApiList>
  </div>
</template>
