import toLower from 'lodash/toLower';
import get from 'lodash/get';
import {
  DATE_FORMAT,
  TIME_ZONE,
  formatUtc,
} from '@emobg/web-utils';

import { LOCATION_STATUS_LABELS } from '../const/stationHistory.const';
import StationLink from '../../../../components/StationLink.vue';

export const contentCells = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    title: 'Station',
    component: StationLink,
    props: result => ({
      stationUuid: get(result, 'station.internalId'),
      stationId: get(result, 'station.id'),
    }),
    width: 270,
  },
  {
    title: 'Geofence name',
    property: 'geofenceName',
    width: 270,
  },
  {
    title: 'Location status',
    property: 'type',
    width: 270,
    transformValue: value => LOCATION_STATUS_LABELS[toLower(value)],
  },
  {
    title: 'Date/Time',
    property: 'occurredOn',
    width: 180,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
];
