var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ConnectivityCell mb-3" },
    [
      _c(
        "h3",
        {
          staticClass: "mb-1",
          attrs: { "data-test-id": "connectivity-label" },
        },
        [_vm._v(" Connectivity ")]
      ),
      _vm._l(_vm.connectivityInfo, function (providerInfo, index) {
        return _c(
          "div",
          { key: index, staticClass: "info-box mb-1" },
          [
            _c("ui-icon", {
              staticClass: "mr-2 d-inline-block emobg-color-black",
              attrs: { size: _vm.ICONS_SIZES.large, icon: _vm.ICONS.carWifi },
            }),
            _c(
              "ui-badge",
              {
                attrs: {
                  color: providerInfo.color,
                  "font-class": "emobg-font-small emobg-font-weight-normal",
                  "data-test-id": "device_status-badge",
                },
              },
              [_vm._v(" " + _vm._s(providerInfo.label) + " ")]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }