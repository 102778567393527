export const CONNECTIVITY_STATUS = {
  connected: 'connected',
  notConnected: 'Not connected',
};

export const PROVIDER_NAMES = {
  oem: 'OEM',
  geotab: 'Geotab',
  invers: 'Invers',
};
