import get from 'lodash/get';

import { LIST_HEADERS } from '../const/movements.const';

import DoubleHeader from '../components/DoubleHeader.vue';
import MovementIdentifier from '../components/MovementIdentifier.vue';
import StationDateTime from '../components/StationDateTime.vue';

export const contentCells = [
  {
    component: MovementIdentifier,
    props: result => ({
      movementNumber: get(result, 'rentalAgreementNumber'),
      movementType: get(result, 'type'),
    }),
    headerComponent: DoubleHeader,
    headerProps: () => ({
      mainHeaderText: LIST_HEADERS.movementsIdentifiers,
      subHeaderLeft: LIST_HEADERS.movementNumber,
      subHeaderRight: LIST_HEADERS.movementType,
    }),
    width: 210,
  },
  {
    component: StationDateTime,
    props: result => ({
      station: get(result, 'startLocationId'),
      dateTime: get(result, 'startTime'),
    }),
    headerComponent: DoubleHeader,
    headerProps: () => ({
      mainHeaderText: LIST_HEADERS.checkoutStart,
      subHeaderLeft: LIST_HEADERS.station,
      subHeaderRight: LIST_HEADERS.dateTime,
    }),
    width: 210,
  },
  {
    component: StationDateTime,
    props: result => ({
      station: get(result, 'endLocationId'),
      dateTime: get(result, 'endTime'),
    }),
    headerComponent: DoubleHeader,
    headerProps: () => ({
      mainHeaderText: LIST_HEADERS.checkoutEnd,
      subHeaderLeft: LIST_HEADERS.station,
      subHeaderRight: LIST_HEADERS.dateTime,
    }),
    width: 210,
  },
];
