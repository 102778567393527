<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import map from 'lodash/map';

import { CONNECTIVITY_STATUS, PROVIDER_NAMES } from '../const/connectivity.const';

export default {
  name: 'ConnectivityCell',
  props: {
    cell: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    providerNames() {
      return get(this.cell, 'data.names') || [];
    },
    connectivityInfo() {
      if (isEmpty(this.providerNames)) {
        return [{
          label: upperFirst(CONNECTIVITY_STATUS.notConnected),
          color: this.COLORS.danger,
        }];
      }
      return map(this.providerNames, name => ({
        label: `${PROVIDER_NAMES[name]} ${CONNECTIVITY_STATUS.connected}`,
        color: this.COLORS.success,
      }));
    },
  },
};
</script>
<template>
  <div class="ConnectivityCell mb-3">
    <h3
      class="mb-1"
      data-test-id="connectivity-label"
    >
      Connectivity
    </h3>
    <div
      v-for="(providerInfo, index) in connectivityInfo"
      :key="index"
      class="info-box mb-1"
    >
      <ui-icon
        :size="ICONS_SIZES.large"
        :icon="ICONS.carWifi"
        class="mr-2 d-inline-block emobg-color-black"
      />
      <ui-badge
        :color="providerInfo.color"
        font-class="emobg-font-small emobg-font-weight-normal"
        data-test-id="device_status-badge"
      >
        {{ providerInfo.label }}
      </ui-badge>
    </div>
  </div>
</template>
