var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "TelemetryCharts",
      attrs: { "data-test-id": "telemetry_charts-component" },
    },
    [
      _vm.mileage.length && _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.mileage]
        ? _c(
            "ui-card",
            {
              class: _vm.cardClass,
              attrs: {
                header: _vm.CHARTS.mileage.label,
                "data-test-id": "mileage-card",
              },
            },
            [
              _vm.mileage.length
                ? _c("ChartComponent", {
                    staticClass: "w-100",
                    attrs: {
                      id: `${_vm.CHARTS.mileage.id}${
                        _vm.isForPdf ? "_pdf" : ""
                      }`,
                      "chart-options": _vm.getChartOptions(
                        _vm.mileage,
                        _vm.CHART_TYPES.mileage,
                        _vm.isForPdf,
                        _vm.mileageUnit
                      ),
                      "data-test-id": "mileage-chart",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.fuel.length && _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.fuel]
        ? _c(
            "ui-card",
            {
              class: _vm.cardClass,
              attrs: {
                header: _vm.CHARTS.fuel.label,
                "data-test-id": "fuel-card",
              },
            },
            [
              _vm.fuel.length
                ? _c("ChartComponent", {
                    staticClass: "w-100",
                    attrs: {
                      id: `${_vm.CHARTS.fuel.id}${_vm.isForPdf ? "_pdf" : ""}`,
                      "chart-options": _vm.getChartOptions(
                        _vm.fuel,
                        _vm.CHART_TYPES.fuel,
                        _vm.isForPdf
                      ),
                      "data-test-id": "fuel-chart",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.stateOfCharge.length &&
      _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.stateOfCharge]
        ? _c(
            "ui-card",
            {
              class: _vm.cardClass,
              attrs: {
                header: _vm.CHARTS.stateOfCharge.label,
                "data-test-id": "state_of_charge-card",
              },
            },
            [
              _vm.stateOfCharge.length
                ? _c("ChartComponent", {
                    staticClass: "w-100",
                    attrs: {
                      id: `${_vm.CHARTS.stateOfCharge.id}${
                        _vm.isForPdf ? "_pdf" : ""
                      }`,
                      "chart-options": _vm.getChartOptions(
                        _vm.stateOfCharge,
                        _vm.CHART_TYPES.stateOfCharge,
                        _vm.isForPdf
                      ),
                      "data-test-id": "charge-chart",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.battery.length &&
      _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.batteryLevel]
        ? _c(
            "ui-card",
            {
              class: _vm.cardClass,
              attrs: {
                header: _vm.CHARTS.battery.label,
                "data-test-id": "battery-card",
              },
            },
            [
              _vm.battery.length
                ? _c("ChartComponent", {
                    staticClass: "w-100",
                    attrs: {
                      id: `${_vm.CHARTS.battery.id}${
                        _vm.isForPdf ? "_pdf" : ""
                      }`,
                      "chart-options": _vm.getChartOptions(
                        _vm.battery,
                        _vm.CHART_TYPES.battery,
                        _vm.isForPdf
                      ),
                      "data-test-id": "battery-chart",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }