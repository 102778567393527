var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "TelemetryPdf emobg-background-color-white p-4",
      attrs: { id: _vm.TELEMETRY_PDF_ID },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between align-items-center pb-4 mb-4 emobg-border-bottom-1 emobg-border-color-ground-light",
        },
        [
          _c("img", {
            attrs: {
              src: "/carrental/logo.svg",
              width: "100px",
              height: "50px",
              alt: "logo",
            },
          }),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("h2", { staticClass: "mb-2" }, [
              _vm._v(
                " License plate: " +
                  _vm._s(
                    _vm.get(
                      _vm.vehicleData,
                      "license_plate",
                      _vm.FALLBACK_MESSAGE.notAvailable
                    )
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "emobg-font-small" }, [
              _vm._v(
                " Model: " +
                  _vm._s(
                    _vm.get(
                      _vm.vehicleData,
                      "model",
                      _vm.FALLBACK_MESSAGE.notAvailable
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "ui-card",
        {
          staticClass: "noBorderCard d-block",
          attrs: { header: "Telemetry report", "data-test-id": "summary-card" },
        },
        [
          _c("TelemetrySummary", {
            attrs: {
              "query-dates": _vm.queryDates,
              "selected-telemetry": _vm.selectedTelemetry,
              "is-for-pdf": "",
              "data-test-id": "telemetry_summary-component",
            },
          }),
        ],
        1
      ),
      _c("TelemetryCharts", {
        staticClass: "mb-3",
        attrs: {
          "selected-telemetry": _vm.selectedTelemetry,
          "is-for-pdf": "",
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "emobg-font-x-small emobg-color-ink" }, [
      _c("p", [
        _vm._v(
          " Please note that your personal data has been collected by Europcar through your rental journey. For more information about these processing activities, you can consult our dedicated privacy policy on connected vehicles: https://www.europcar.com/files/live/sites/erc/files/connected-cars/privacy-policy.pdf "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          " In accordance with the applicable laws and regulations, you have the right to access, to rectify, to erase, to object, to data portability, or to request the limitation of the processing of your personal data. You can exercise these rights at any time by sending an email to the following email address dpo@europcar.com or by contacting our customer service department. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }