<script>
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';

import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  FALLBACK_MESSAGE,
  navigationErrorHandler,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import carRental from '../../../store/CarrentalModuleMap';
import carrentalRouter from '../../../router/CarrentalRouterMap';
import { updateQueryDates } from '../utils/queryDates';
import { DATE_SCOPES } from '../const/date.const';

export default {
  name: 'SummaryTripsView',
  data() {
    return {
      isSameOrBefore: undefined,
      isWithinBoundaries: undefined,
      endMomentDate: '',
      isSidebarOpen: false,
      queryDates: {
        from: '',
        to: '',
      },
      reason: '',
      comment: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userUuid: state => state.user.data.uuid,
      operatorCountryCode: state => state.operators.countryCode,
    }),
    ...mapState(carRental.vehicle.trips, {
      processingReasons: state => map(state.reasons.data.reasons || [],
        (value) => ({ label: value, value })),
    }),
    ...mapState(carRental.trip.details, {
      hasError: state => state.summary.STATUS.ERROR,
    }),
    ...mapState(carRental.vehicle.summary, {
      summaryData: state => get(state, 'summary.data.components') || {},
    }),
    isDateValid() {
      return this.isSameOrBefore && this.isWithinBoundaries;
    },
    vehicleLicensePlate() {
      const componentData = find(this.summaryData || [], { type: 'vehicleComponent' });
      return get(componentData, 'data.license_plate', FALLBACK_MESSAGE.notAvailable);
    },
    endValidDateRange() {
      if (!this.queryDates.from) {
        return null;
      }

      const startDate = moment(this.queryDates.from);
      const endDate = moment(startDate).add(1, 'months');
      return {
        start: startDate,
        end: endDate.isSameOrBefore(moment()) ? endDate : moment(),
      };
    },
  },
  watch: {
    queryDates: {
      handler() {
        const fromDate = moment(this.queryDates.from);
        const toDate = moment(this.queryDates.to);

        this.endMomentDate = toDate;
        this.isWithinBoundaries = toDate.diff(fromDate, 'days') < 31;
        this.isSameOrBefore = fromDate.isSameOrBefore(toDate);
      },
      deep: true,
    },
  },
  mounted() {
    this.getProcessingReasons();
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_SCOPES = DATE_SCOPES;
    this.vehicleVin = get(this, '$route.params.vehicleVin');
    this.startValidDateRange = {
      start: moment().subtract(365, 'days'),
      end: moment(),
    };
  },
  methods: {
    ...mapActions(carRental.vehicle.trips, [
      'getProcessingReasons',
    ]),
    ...mapMutations(carRental.vehicle.trips, [
      'updateVehicleTripData',
    ]),
    ...mapActions(carRental.trip.details, [
      'getVehicleTrips',
    ]),
    async requestTripDetails() {
      this.updateVehicleTripData({
        dateRange: this.queryDates,
        vehicleLicensePlate: this.vehicleLicensePlate,
        reason: this.reason,
        comment: this.comment,
        userId: this.userUuid,
      });
      await this.getVehicleTrips({
        vehicleVin: this.vehicleVin,
        data: {
          ...this.queryDates,
          reason: this.reason,
          comment: this.comment,
          userId: this.userUuid,
          countryCode: this.operatorCountryCode,
        },
      });

      if (!this.hasError) {
        this.$router.push(
          {
            name: carrentalRouter.vehicles.tripsSummary,
            params: {
              vehicleVin: this.vehicleVin,
            },
          },
        ).catch(navigationErrorHandler);
      }
    },
    updateQueryDates,
  },
};
</script>
<template>
  <div
    class="SummaryTripsView"
    data-test-id="summary_trips-view"
  >
    <ui-card
      header="Request trip details"
      description="Specify which trip details you want to review. Trips requested cannot be older than 12 months"
      class="d-block mb-4"
      data-test-id="request_trip-card"
    >
      <div class="row">
        <ui-select
          v-if="processingReasons.length"
          :value="reason"
          :options.prop="processingReasons"
          label="Processing reason"
          placeholder="Select processing reason"
          data-test-id="processing_reason-select"
          class="col-12 col-lg-6 my-2"
          @selectoption="({ detail }) => reason = detail"
        />
      </div>
      <div class="row">
        <ui-alert
          v-if="queryDates.from && queryDates.to && !isDateValid"
          data-test-id="date_validation-alert"
          :color="COLORS.danger"
          class="col-6 mb-2 w-100"
        >
          "From:" date must be before "To:" date and difference must be within 30 days
        </ui-alert>
      </div>
      <div class="row">
        <ui-datetimepicker
          :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
          :valid-date-range.prop="startValidDateRange"
          :minutes-interval="1"
          :size="SIZES.small"
          required
          placeholder="Select from date"
          label="From:"
          class="col-6 col-lg-3"
          data-test-id="from-datepicker"
          @datechanged="({ detail }) => updateQueryDates(queryDates, detail, DATE_SCOPES.from)"
        />
        <ui-datetimepicker
          :date.prop="endMomentDate"
          :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
          :valid-date-range.prop="endValidDateRange"
          :minutes-interval="1"
          :size="SIZES.small"
          required
          placeholder="Select to date"
          label="To:"
          class="col-6 col-lg-3"
          data-test-id="to-datepicker"
          @datechanged="({ detail }) => updateQueryDates(queryDates, detail, DATE_SCOPES.to)"
        />
        <ui-text-area
          :value="comment"
          data-test-id="comment"
          placeholder="Describe why do you need this information"
          label="Comment (optional)"
          name="comment"
          class="col-12 mt-4"
          rows="4"
          @changevalue="({ detail }) => comment = detail"
        />
        <div class="col-12 d-flex justify-content-end">
          <ui-button
            data-test-id="request-button"
            :disabled="(!queryDates.from || !queryDates.to) || !isDateValid || !reason"
            class="mt-2"
            @clickbutton="requestTripDetails"
          >
            Request details
          </ui-button>
        </div>
      </div>
    </ui-card>
  </div>
</template>
