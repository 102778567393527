var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "InfoSection mb-3",
      attrs: { "data-test-id": "info_section-component" },
    },
    [
      _c(
        "h4",
        {
          staticClass: "emobg-font-weight-bold emobg-font-small mb-2",
          attrs: { "data-test-id": `${_vm.snakeCase(_vm.title)}_title` },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "emobg-font-small mb-1",
          attrs: { "data-test-id": `${_vm.snakeCase(_vm.title)}_value` },
        },
        [_vm._v(" " + _vm._s(_vm.textValue) + " ")]
      ),
      !_vm.isForPdf
        ? _c(
            "div",
            {
              staticClass:
                "emobg-font-weight-light emobg-font-small emobg-color-ink-light",
              attrs: { "data-test-id": `${_vm.snakeCase(_vm.title)}_date` },
            },
            [
              _vm._v(
                " Updated: " +
                  _vm._s(
                    _vm.formatUtc(
                      _vm.date,
                      _vm.DATE_FORMAT.defaultExtended,
                      _vm.operatorTimezone
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }