export const TELEMETRY_OPTIONS = Object.freeze({
  mileage: 'Mileage',
  stateOfCharge: 'State of charge',
  batteryLevel: 'Battery',
  fuel: 'Fuel',
});

export const TELEMETRY_KEYS = Object.freeze({
  mileage: 'mileage',
  stateOfCharge: 'stateOfCharge',
  batteryLevel: 'batteryLevel',
  fuel: 'fuel',
});

export const TELEMETRY_SORT_OPTIONS = Object.freeze({
  [TELEMETRY_KEYS.mileage]: -2,
  [TELEMETRY_KEYS.fuel]: -1,
  [TELEMETRY_KEYS.stateOfCharge]: 0,
  [TELEMETRY_KEYS.batteryLevel]: 1,
});

export const TELEMETRY_PDF_ID = 'pdfContent';
