import map from 'lodash/map';
import round from 'lodash/round';
import { DISTANCE_UNIT } from '@emobg/web-utils';
import { CHART_CURVES } from '../../../../const/chart.const';
import { getChartsConfig } from '../const/chart.const';

export const getChartOptions = (data, chartType, isForPdf, mileageUnit = DISTANCE_UNIT.kilometers) => {
  const chartsConfig = getChartsConfig(mileageUnit);
  const chartConfig = chartsConfig[chartType];

  return {
    chart: {
      id: chartConfig.id,
      width: '99%',
      height: isForPdf ? 220 : 350,
      type: 'area',
      toolbar: {
        show: !isForPdf,
        tools: {
          download: false,
          selection: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: chartConfig.color,
        shadeTo: 'dark',
        shadeIntensity: 50,
      },
    },
    series: [{
      name: `${chartConfig.label} [${chartConfig.unit}]`,
      data: map(data, item => round(item.value, 2)),
    }],
    stroke: {
      curve: CHART_CURVES.stepline,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      categories: map(data, 'occurredOn'),
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: chartConfig.yaxis,
    tooltip: {
      x: {
        format: 'dd/MM/yyyy HH:mm',
      },
    },
  };
};
