<script>
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiTabs } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import { HTTP_STATUS_CODES, navigationErrorHandler } from '@emobg/web-utils';

import carRental from '../../store/CarrentalModuleMap';
import { CARRENTAL_PERMISSIONS } from '../../const/permissions';
import { VEHICLE_TABS } from './const/tabs.const';
import { CARRENTAL_FLAGS } from '../../const/features';

import { scopes } from './store/VehicleSummaryModule';
import { VehicleSummaryHeaderComponent } from './components';

export default {
  name: 'VehiclesSummaryView',
  components: {
    MuiTabs,
    VehicleSummaryHeaderComponent,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapState(carRental.vehicle.summary, {
      summaryStatus: state => state.summary.STATUS,
      summaryError: state => get(state, 'summary.error') || {},
      summaryData: state => get(state, 'summary.data.components') || {},
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      features: state => get(state, 'features.data'),
    }),
    activeTab() {
      const { path } = this.$route;
      const tabIndex = findIndex(this.tabs, tab => includes(path, tab.url));

      return tabIndex < 0 ? 0 : tabIndex;
    },
  },
  watch: {
    features: {
      handler() {
        this.generateTabs();
      },
      immediate: true,
    },
  },
  created() {
    this.vehicleVin = this.$route.params.vehicleVin;
    this.CARRENTAL_FLAGS = CARRENTAL_FLAGS;
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
  },
  async mounted() {
    await this.getVehicleSummary({ vehicleVin: this.vehicleVin });
    if (this.summaryError.status === HTTP_STATUS_CODES.notFound) {
      this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      this.clearErrors({ scope: scopes.summary });
    } else {
      this.getVehicleBasicInformation(this.vehicleVin);
    }
  },
  destroyed() {
    this.resetSummaryData();
  },
  methods: {
    ...mapActions(carRental.vehicle.summary, [
      'getVehicleSummary',
    ]),
    ...mapMutations(carRental.vehicle.summary, [
      'resetSummaryData',
      'clearErrors',
    ]),
    ...mapActions(carRental.vehicle.info, [
      'getVehicleBasicInformation',
    ]),
    includes,
    updateRoute(url) {
      this.$router.push({ path: url }).catch(navigationErrorHandler);
    },
    generateTabs() {
      const visibleTabs = map(VEHICLE_TABS, tab => {
        const hasPermissions = !isEmpty(intersection(tab.permissions, this.permissions));
        const isEnabled = !tab.feature ? true : this.features[tab.feature];
        return (hasPermissions && isEnabled) ? tab : null;
      });
      this.tabs = compact(visibleTabs);
    },
  },
};
</script>

<template>
  <div
    class="VehiclesSummaryView"
    data-test-id="vehicles_summary-view"
  >
    <div class="d-block pt-3 emobg-background-color-white">
      <VehicleSummaryHeaderComponent
        v-if="includes(permissions, CARRENTAL_PERMISSIONS.vehicleViewDetailsHeader)"
        :status="summaryStatus"
        :data="summaryData"
        class="mt-2 px-lg-5"
        data-test-id="vehicle_summary_header"
      />
      <MuiTabs
        v-if="tabs.length"
        :tabs="tabs"
        :active="activeTab"
        class="mt-1 mb-n3 pl-5"
        @active-tab="updateRoute"
      />
    </div>
    <div class="pt-4 px-2 mt-3 px-lg-5">
      <Transition name="page">
        <RouterView />
      </Transition>
    </div>
  </div>
</template>
