<script>
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import snakeCase from 'lodash/snakeCase';
import isNil from 'lodash/isNil';
import { mapState } from 'vuex';
import {
  DATE_FORMAT, formatThousands, formatUtc, TIME_ZONE,
} from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mileageUnitMixin } from '../../../mixins';

export default {
  name: 'HeaderCell',
  mixins: [mileageUnitMixin],
  props: {
    cell: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    valueProp: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    timeStamp() {
      return get(this.cell, 'data.timestamp');
    },
    value() {
      return get(this.cell, `data.${this.valueProp}`);
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    formatUtc,
    formatThousands,
    snakeCase,
    isNil,
    toLower,
  },
};
</script>
<template>
  <div class="HeaderCell mb-3">
    <div class="d-flex align-items-center mb-1">
      <h3 :data-test-id="`${snakeCase(label)}-label`">
        {{ label }}
      </h3>
      <ui-tooltip
        v-if="tooltip"
        :tooltip="tooltip"
      >
        <ui-icon
          :color="GRAYSCALE.inkLight"
          :size="ICONS_SIZES.small"
          :icon="ICONS.infoFull"
          class="ml-1"
        />
      </ui-tooltip>
    </div>
    <div class="info-box mb-1">
      <ui-icon
        v-if="icon"
        :size="ICONS_SIZES.large"
        :icon="icon"
        class="mr-2 d-inline-block emobg-color-black"
      />
      <div
        :data-test-id="`${snakeCase(label)}-value`"
        class="d-flex emobg-body-1"
      >
        <span v-if="valueProp === 'level'">
          {{ value ? `${value} ${ unit }` : FALLBACK_MESSAGE.noData }}
        </span>
        <span v-else-if="valueProp === 'fuel_level' || valueProp === 'state_of_charge'">
          {{ isNil(value) ? FALLBACK_MESSAGE.noData : `${value} %` }}
        </span>
        <span v-else-if="valueProp === 'mileage_level'">
          {{ value ? formatDistance(value, mileageUnit, 0) : FALLBACK_MESSAGE.noData }}
        </span>
        <span v-else>
          {{ value || FALLBACK_MESSAGE.noData }}
        </span>
      </div>
    </div>
    <span
      :data-test-id="`${snakeCase(label)}-timestamp`"
      class="emobg-body-1 emobg-color-ink-light"
    >
      Updated:
      {{ timeStamp ?
        formatUtc(timeStamp, DATE_FORMAT.defaultExtended, operatorTimezone) :
        FALLBACK_MESSAGE.noData
      }}
    </span>
  </div>
</template>
