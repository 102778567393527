var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "TelemetrySummary row" }, [
    _c(
      "div",
      { staticClass: "col-6", attrs: { "data-test-id": "data_from" } },
      [
        _c(
          "div",
          {
            staticClass:
              "mb-3 pb-1 emobg-border-bottom-1 emobg-border-color-ground-light",
          },
          [
            _c(
              "h3",
              { staticClass: "emobg-font-weight-bold emobg-font-default mb-1" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isForPdf
                        ? "Data from (Pick up)"
                        : "Data available from"
                    ) +
                    " "
                ),
              ]
            ),
            _c("span", { staticClass: "emobg-font-small" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatUtc(
                      _vm.queryDates.from,
                      _vm.DATE_FORMAT.defaultExtended,
                      _vm.operatorTimezone
                    )
                  ) +
                  " "
              ),
            ]),
          ]
        ),
        !_vm.isEmpty(_vm.mileageInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.mileage]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.mileageInfo.from.value,
                date: _vm.mileageInfo.from.date,
                "is-for-pdf": _vm.isForPdf,
                title: "Mileage",
              },
            })
          : _vm._e(),
        !_vm.isEmpty(_vm.fuelInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.fuel]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.fuelInfo.from.value,
                date: _vm.fuelInfo.from.date,
                "is-for-pdf": _vm.isForPdf,
                title: "Fuel level",
              },
            })
          : _vm._e(),
        !_vm.isEmpty(_vm.stateOfChargeInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.stateOfCharge]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.stateOfChargeInfo.from.value,
                date: _vm.stateOfChargeInfo.from.date,
                "is-for-pdf": _vm.isForPdf,
                title: "State of charge",
              },
            })
          : _vm._e(),
        !_vm.isEmpty(_vm.batteryInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.batteryLevel]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.batteryInfo.from.value,
                date: _vm.batteryInfo.from.date,
                "is-for-pdf": _vm.isForPdf,
                title: "Battery",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-6", attrs: { "data-test-id": "data_to" } },
      [
        _c(
          "div",
          {
            staticClass:
              "mb-3 pb-1 emobg-border-bottom-1 emobg-border-color-ground-light",
          },
          [
            _c(
              "h3",
              { staticClass: "emobg-font-weight-bold emobg-font-default mb-1" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isForPdf ? "Data to (Return)" : "Data available to"
                    ) +
                    " "
                ),
              ]
            ),
            _c("span", { staticClass: "emobg-font-small" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatUtc(
                      _vm.queryDates.to,
                      _vm.DATE_FORMAT.defaultExtended,
                      _vm.operatorTimezone
                    )
                  ) +
                  " "
              ),
            ]),
          ]
        ),
        !_vm.isEmpty(_vm.mileageInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.mileage]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.mileageInfo.to.value,
                date: _vm.mileageInfo.to.date,
                "is-for-pdf": _vm.isForPdf,
                title: "Mileage",
              },
            })
          : _vm._e(),
        !_vm.isEmpty(_vm.fuelInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.fuel]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.fuelInfo.to.value,
                date: _vm.fuelInfo.to.date,
                "is-for-pdf": _vm.isForPdf,
                title: "Fuel level",
              },
            })
          : _vm._e(),
        !_vm.isEmpty(_vm.stateOfChargeInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.stateOfCharge]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.stateOfChargeInfo.to.value,
                date: _vm.stateOfChargeInfo.to.date,
                "is-for-pdf": _vm.isForPdf,
                title: "State of charge",
              },
            })
          : _vm._e(),
        !_vm.isEmpty(_vm.batteryInfo) &&
        _vm.selectedTelemetry[_vm.TELEMETRY_KEYS.batteryLevel]
          ? _c("InfoSection", {
              attrs: {
                "text-value": _vm.batteryInfo.to.value,
                date: _vm.batteryInfo.to.date,
                "is-for-pdf": _vm.isForPdf,
                title: "Battery",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }