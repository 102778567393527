<script>
export default {
  name: 'NoFuelOrChargeCell',
};
</script>

<template>
  <div class="NoFuelOrChargeCell mb-3">
    <h3
      class="mb-1"
      data-test-id="no_fuel_or_charge-label"
    >
      Unspecified fuel/power
    </h3>
    <div class="info-box mb-1">
      <ui-icon
        :size="ICONS_SIZES.large"
        :icon="ICONS.statusUnknown"
        class="mr-2 d-inline-block emobg-color-black"
      />
      <div
        class="d-flex emobg-body-1"
        data-test-id="no_fuel_or_charge-value"
      >
        {{ FALLBACK_MESSAGE.noData }}
      </div>
    </div>
    <span
      class="emobg-body-1 emobg-color-ink-light"
      data-test-id="timestamp"
    >
      Updated:
      {{ FALLBACK_MESSAGE.noData }}
    </span>
  </div>
</template>
