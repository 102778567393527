<script>
import map from 'lodash/map';

import { MuiModal } from '@emobg/vue-base';

import { mapActions, mapState } from 'vuex';
import carRental from '../../../store/CarrentalModuleMap';

export default {
  name: 'RevealLocationModal',
  components: {
    MuiModal,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    lastUpdate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      modalConfig: {
        title: 'Request vehicle position',
        isClosable: true,
      },
      selectedReason: '',
    };
  },
  computed: {
    ...mapState(carRental.vehicle.trips, {
      positionRevealReasons: state => map(state.positionRevealReasons.data.reasons || [], (value) => ({ label: value, value })),
    }),
  },
  watch: {
    isModalVisible(newValue) {
      this.isOpen = newValue;
    },
  },
  mounted() {
    this.getVehiclePositionReasons();
  },
  methods: {
    ...mapActions(carRental.vehicle.trips, [
      'getVehiclePositionReasons',
    ]),
    onCloseModal() {
      this.selectedReason = '';
      this.$emit('close');
    },
    onRevealLocation() {
      this.$emit('reveal', this.selectedReason);
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="modalConfig"
    v-model="isOpen"
    class="RevealLocationModal"
    data-test-id="reveal_location-modal"
    @modal-closed="onCloseModal"
  >
    <template slot="body">
      <ui-alert
        :color="COLORS.primary"
        :icon="ICONS.infoFull"
        class="d-block mb-3"
        data-test-id="last_update-alert"
      >
        <p class="emobg-body-1">
          {{ `Data last received: ${lastUpdate}` }}
        </p>
      </ui-alert>
      <h3 class="emobg-color-ink mb-3">
        Vehicle location is sensitive information, select from reasons below
      </h3>
      <ui-radio
        v-for="(reason, index) in positionRevealReasons"
        :key="index"
        :value="selectedReason"
        :caption="reason.value"
        :option="reason.value"
        name="revealReasons"
        class="mb-2 d-block"
        @changevalue="({ detail }) => selectedReason = detail"
      />
    </template>
    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <ui-button
        :color="GRAYSCALE.inkLight"
        :face="FACES.text"
        data-test-id="cancel-button"
        class="mr-4"
        @clickbutton="onCloseModal"
      >
        Cancel
      </ui-button>

      <ui-button
        :disabled="!selectedReason"
        data-test-id="confirm-button"
        @clickbutton="onRevealLocation"
      >
        Confirm
      </ui-button>
    </div>
  </MuiModal>
</template>
