var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "NoFuelOrChargeCell mb-3" }, [
    _c(
      "h3",
      {
        staticClass: "mb-1",
        attrs: { "data-test-id": "no_fuel_or_charge-label" },
      },
      [_vm._v(" Unspecified fuel/power ")]
    ),
    _c(
      "div",
      { staticClass: "info-box mb-1" },
      [
        _c("ui-icon", {
          staticClass: "mr-2 d-inline-block emobg-color-black",
          attrs: { size: _vm.ICONS_SIZES.large, icon: _vm.ICONS.statusUnknown },
        }),
        _c(
          "div",
          {
            staticClass: "d-flex emobg-body-1",
            attrs: { "data-test-id": "no_fuel_or_charge-value" },
          },
          [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.noData) + " ")]
        ),
      ],
      1
    ),
    _c(
      "span",
      {
        staticClass: "emobg-body-1 emobg-color-ink-light",
        attrs: { "data-test-id": "timestamp" },
      },
      [_vm._v(" Updated: " + _vm._s(_vm.FALLBACK_MESSAGE.noData) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }