var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "SummaryTelemetryView",
      attrs: { "data-test-id": "summary_telemetry-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h1", { staticClass: "emobg-font-x-large my-4" }, [
            _vm._v(" Telemetry "),
          ]),
          _c(
            "ui-button",
            {
              attrs: {
                loading: _vm.isDownloading,
                disabled: _vm.isTelemetryEmpty || !_vm.hasSelectedTelemetry,
                "data-test-id": "list_export-button",
              },
              on: { clickbutton: _vm.doDownloadPdf },
            },
            [
              _c("ui-icon", {
                attrs: { size: _vm.SIZES.small, icon: _vm.ICONS.download },
              }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Export results")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "ui-card",
        {
          staticClass: "d-block mb-4",
          attrs: {
            "data-test-id": "request_telemetry-card",
            header: "Filter telemetry",
          },
        },
        [
          _c(
            "div",
            { staticClass: "row no-gutters" },
            [
              _c("ui-select", {
                staticClass: "col-6 pr-3",
                attrs: {
                  size: _vm.SIZES.small,
                  searchbox: { threshold: 0 },
                  clear: "",
                  placeholder: "Search for RA number",
                  label: "Search by RA number",
                },
                domProps: {
                  value: _vm.selectedMovementId,
                  options: _vm.movementsOptions,
                },
                on: {
                  selectoption: ({ detail }) => _vm.onSelectMovement(detail),
                },
              }),
              _c("ui-datetimepicker", {
                staticClass:
                  "DatePicker DatePicker--noBorderRight col-6 col-lg-3",
                attrs: {
                  "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                  size: _vm.SIZES.small,
                  required: "",
                  placeholder: "Select from date",
                  label: "Data from",
                  "data-test-id": "from-datepicker",
                },
                domProps: {
                  date: _vm.fromDate,
                  validDateRange: _vm.calendarDateRange,
                },
                on: {
                  datechanged: ({ detail }) =>
                    _vm.onDateChanged(detail, _vm.DATE_SCOPES.from),
                },
              }),
              _c("ui-datetimepicker", {
                staticClass:
                  "DatePicker DatePicker--noBorderRadiusLeft col-6 col-lg-3",
                attrs: {
                  "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                  size: _vm.SIZES.small,
                  required: "",
                  placeholder: "Select to date",
                  label: "Data to",
                  "data-test-id": "to-datepicker",
                },
                domProps: {
                  date: _vm.toDate,
                  validDateRange: _vm.endValidDateRange,
                },
                on: {
                  datechanged: ({ detail }) =>
                    _vm.onDateChanged(detail, _vm.DATE_SCOPES.to),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpty(_vm.telemetryAvailableOptions),
                  expression: "!isEmpty(telemetryAvailableOptions)",
                },
              ],
              staticClass: "row no-gutters mt-3",
            },
            _vm._l(_vm.telemetryAvailableOptions, function (option) {
              return _c(
                "div",
                {
                  key: option,
                  staticClass: "row justify-content-between align-items-center",
                },
                [
                  _c("ui-checkbox", {
                    staticClass: "col mr-3",
                    attrs: {
                      checked: _vm.selectedTelemetry[option],
                      caption: _vm.TELEMETRY_OPTIONS[option],
                    },
                    on: {
                      changevalue: ({ detail }) =>
                        (_vm.selectedTelemetry[option] = detail),
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "row no-gutters mt-3" },
            [
              !_vm.areQueryDatesInRange
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "col",
                      attrs: {
                        color: _vm.COLORS.danger,
                        icon: _vm.ICONS.alertFull,
                        "data-test-id": "date_validation-alert",
                      },
                    },
                    [
                      _vm._v(
                        " Selected period must be at most " +
                          _vm._s(_vm.TELEMETRY_SETTINGS.maxPeriod) +
                          " days long and within the last " +
                          _vm._s(_vm.TELEMETRY_SETTINGS.maxDays) +
                          " days. "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isLoading && _vm.isTelemetryEmpty && _vm.areQueryDatesInRange
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "col",
                      attrs: {
                        color: _vm.COLORS.danger,
                        icon: _vm.ICONS.alertFull,
                        "data-test-id": "no_data-alert",
                      },
                    },
                    [
                      _vm._v(
                        " No telemetry data for period: " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.get(_vm.queryDates, _vm.DATE_SCOPES.from),
                              _vm.DATE_FORMAT.defaultExtended,
                              _vm.operatorTimezone
                            )
                          ) +
                          " - " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.get(_vm.queryDates, _vm.DATE_SCOPES.to),
                              _vm.DATE_FORMAT.defaultExtended,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      !_vm.isTelemetryEmpty && _vm.hasSelectedTelemetry
        ? _c(
            "ui-card",
            {
              staticClass: "d-block mb-4",
              attrs: {
                header: "Telemetry summary",
                "data-test-id": "summary-card",
              },
            },
            [
              _c("TelemetrySummary", {
                attrs: {
                  "query-dates": _vm.queryDates,
                  "selected-telemetry": _vm.selectedTelemetry,
                  "data-test-id": "telemetry_summary-component",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("TelemetryCharts", {
        attrs: { "selected-telemetry": _vm.selectedTelemetry },
      }),
      _c(
        "div",
        { staticClass: "SummaryTelemetryView__wrapperPdf" },
        [
          _c("TelemetryPdf", {
            attrs: {
              "query-dates": _vm.queryDates,
              "selected-telemetry": _vm.selectedTelemetry,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }