import { CARRENTAL_PERMISSIONS } from '../../../const/permissions';
import { CARRENTAL_FLAGS } from '../../../const/features';

export const VEHICLE_TABS = [
  {
    label: {
      title: 'Information',
    },
    url: 'info',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewInformationTab],
  },
  {
    label: {
      title: 'Station Geofence',
    },
    url: 'station-history',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewInformationTab],
  },
  {
    label: {
      title: 'Greenway status',
    },
    url: 'greenway',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewGreenwayTab],
  },
  {
    label: {
      title: 'Alerts',
    },
    url: 'alerts',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewAlertsTab],
  },
  {
    label: {
      title: 'Movements',
    },
    url: 'movements',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewMovementsTab],
  },
  {
    label: {
      title: 'Telemetry',
    },
    url: 'telemetry',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewTelemetryTab],
    feature: CARRENTAL_FLAGS.vehicleTelemetry,
  },
  {
    label: {
      title: 'Trips',
    },
    url: 'trips',
    permissions: [CARRENTAL_PERMISSIONS.vehicleViewTripsTab],
    feature: CARRENTAL_FLAGS.vehicleTripsTab,
  },
];
