<script>
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { mapActions, mapState } from 'vuex';
import { MuiTable } from '@emobg/motion-ui/v1';
import { TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { contentCells } from './config/gwyTable.config';

export default {
  name: 'SummaryEuropcarView',
  components: { MuiTable },
  data() {
    return {
      vehicleVin: get(this, '$route.params.vehicleVin'),
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.vehicle.europcar, {
      isLoading: state => state.gwstatus.STATUS.LOADING,
      gwStatusData: state => get(state, 'gwstatus.data.status') || [],
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    gwStatusDataOrdered() {
      return orderBy(this.gwStatusData, ['timestamp'], ['desc']);
    },
  },
  mounted() {
    this.getVehicleGWStatus(this.vehicleVin);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.vehicle.europcar, [
      'getVehicleGWStatus',
    ]),
    contentCells,
  },
};
</script>

<template>
  <div
    class="SummaryEuropcarView"
    data-test-id="summary_europcar-view"
  >
    <ui-card
      header="Greenway status history"
      class="d-block mb-1"
      data-test-id="gwy-card"
    >
      <MuiTable
        :data-set="gwStatusDataOrdered"
        :content-cells="contentCells({ operatorTimezone })"
        :no-data-label="FALLBACK_MESSAGE.noData"
        :is-loading="isLoading"
        :page-size="20"
        data-test-id="table"
        class="mx-0"
      />
    </ui-card>
  </div>
</template>
