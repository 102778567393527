<script>
import some from 'lodash/some';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import includes from 'lodash/includes';
import { mapState } from 'vuex';

import {
  DATE_FORMAT,
  formatUtc,
  sentenceCase,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { PROPERTY_TO_LABEL_TEXT } from '../constants/vehicleInfo.const';

export default {
  name: 'VehicleDeviceCard',
  props: {
    deviceInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    hasDeviceInfo() {
      return some(this.deviceInfo);
    },
  },
  created() {
    this.PROPERTY_TO_LABEL_TEXT = PROPERTY_TO_LABEL_TEXT;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    snakeCase,
    includes,
    sentenceCase,
    formatUtc,
  },
};
</script>

<template>
  <ui-card
    v-if="hasDeviceInfo"
    header="Device"
    class="mb-4 d-block"
    data-test-id="device-card"
  >
    <div class="row">
      <div
        v-for="(value, key) in deviceInfo"
        :key="key"
        class="col-6 col-xl-3 mb-5"
      >
        <div
          class="mb-1 emobg-body-2"
          :data-test-id="`${snakeCase(key)}-label`"
        >
          {{ includes(key, 'Installed') ? 'Installation status' : sentenceCase(key) }}
        </div>
        <template v-if="includes(key, 'Date') && value">
          <span :data-test-id="`${snakeCase(key)}-value`">
            {{ formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone) }}
          </span>
        </template>
        <template v-else-if="includes(key, 'Installed')">
          <span :data-test-id="`${snakeCase(key)}-value`">{{ value ? 'Installed' : 'Uninstalled' }}</span>
        </template>
        <span
          v-else
          :data-test-id="`${snakeCase(key)}-value`"
        >{{ value || FALLBACK_MESSAGE.notAvailable }}</span>
      </div>
    </div>
  </ui-card>
</template>
