var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        staticClass: "RevealLocationModal",
        attrs: { "data-test-id": "reveal_location-modal" },
        on: { "modal-closed": _vm.onCloseModal },
        model: {
          value: _vm.isOpen,
          callback: function ($$v) {
            _vm.isOpen = $$v
          },
          expression: "isOpen",
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "ui-alert",
            {
              staticClass: "d-block mb-3",
              attrs: {
                color: _vm.COLORS.primary,
                icon: _vm.ICONS.infoFull,
                "data-test-id": "last_update-alert",
              },
            },
            [
              _c("p", { staticClass: "emobg-body-1" }, [
                _vm._v(
                  " " + _vm._s(`Data last received: ${_vm.lastUpdate}`) + " "
                ),
              ]),
            ]
          ),
          _c("h3", { staticClass: "emobg-color-ink mb-3" }, [
            _vm._v(
              " Vehicle location is sensitive information, select from reasons below "
            ),
          ]),
          _vm._l(_vm.positionRevealReasons, function (reason, index) {
            return _c("ui-radio", {
              key: index,
              staticClass: "mb-2 d-block",
              attrs: {
                value: _vm.selectedReason,
                caption: reason.value,
                option: reason.value,
                name: "revealReasons",
              },
              on: {
                changevalue: ({ detail }) => (_vm.selectedReason = detail),
              },
            })
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "ui-button",
            {
              staticClass: "mr-4",
              attrs: {
                color: _vm.GRAYSCALE.inkLight,
                face: _vm.FACES.text,
                "data-test-id": "cancel-button",
              },
              on: { clickbutton: _vm.onCloseModal },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: !_vm.selectedReason,
                "data-test-id": "confirm-button",
              },
              on: { clickbutton: _vm.onRevealLocation },
            },
            [_vm._v(" Confirm ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }