var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MapTagComponent", attrs: { "data-test-id": "map-tag" } },
    [
      _c(
        "h3",
        {
          staticClass: "emobg-font-weight-semibold mb-1",
          attrs: { "data-test-id": "vehicle_position-label" },
        },
        [_vm._v(" Vehicle position ")]
      ),
      _vm.includes(
        _vm.permissions,
        _vm.CARRENTAL_PERMISSIONS.vehicleShowPosition
      )
        ? _c(
            "div",
            [
              _c(
                "ui-button",
                {
                  staticClass: "d-block mb-1",
                  attrs: {
                    face: _vm.FACES.outline,
                    "data-test-id": "location-toggle",
                  },
                  on: { clickbutton: _vm.toggleIsRevealed },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("ui-icon", {
                        attrs: {
                          icon: _vm.isRevealed
                            ? _vm.ICONS.hide
                            : _vm.ICONS.show,
                          "data-test-id": "reveal_hide-status",
                        },
                      }),
                      _c("div", { staticClass: "ml-2 emobg-font-small" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isRevealed ? "Hide position" : "Show position"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "emobg-body-1 emobg-color-ink-light",
                  attrs: { "data-test-id": "vehicle_position-timestamp" },
                },
                [_vm._v(" Updated: " + _vm._s(_vm.lastUpdate) + " ")]
              ),
            ],
            1
          )
        : _c(
            "ui-alert",
            {
              attrs: {
                color: _vm.COLORS.primary,
                icon: _vm.ICONS.infoFull,
                "data-test-id": "vehicle_position-no_permissions-alert",
              },
            },
            [
              _c("p", { staticClass: "emobg-body-1 emobg-color-ink" }, [
                _vm._v(
                  " You don’t have permissions to view the vehicle location map "
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }