var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "SummaryInfoView",
      attrs: { "data-test-id": "summary_info-view" },
    },
    [
      _vm.infoStatus.LOADING
        ? _c("ui-loader")
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("VehicleDescriptionCard", {
                  attrs: { info: _vm.descriptionInfo },
                }),
                _c("VehicleFinancialInfoCard", {
                  attrs: { info: _vm.financialInfo },
                }),
                _c("VehicleDeviceCard", {
                  attrs: { "device-info": _vm.deviceInfo },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }