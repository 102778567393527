var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "VehicleSummaryHeaderComponent",
      attrs: { "data-test-id": "vehicle_summary_header" },
    },
    [
      _vm.status.LOADING
        ? _c("ui-loader")
        : [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _vm.componentData("vehicleComponent")
                    ? _c("div", { staticClass: "emobg-color-ink mb-3" }, [
                        _c(
                          "h2",
                          {
                            staticClass: "mb-1",
                            attrs: { "data-test-id": "plate_number" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(
                                    _vm.componentData("vehicleComponent"),
                                    "data.license_plate",
                                    _vm.FALLBACK_MESSAGE.notAvailable
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "h3",
                          {
                            staticClass: "mb-1",
                            attrs: { "data-test-id": "model" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(
                                    _vm.componentData("vehicleComponent"),
                                    "data.model",
                                    _vm.FALLBACK_MESSAGE.notAvailable
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "h3",
                          {
                            staticClass: "emobg-color-ink-light",
                            attrs: { "data-test-id": "category" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(
                                    _vm.componentData("vehicleComponent"),
                                    "data.category",
                                    _vm.FALLBACK_MESSAGE.notAvailable
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("HeaderCell", {
                    attrs: {
                      cell: _vm.componentData("greenwayComponent"),
                      icon: _vm.ICONS.window,
                      label: "GWY status",
                      "value-prop": "status",
                    },
                  }),
                  _c("HeaderCell", {
                    attrs: {
                      cell: _vm.componentData("movementComponent"),
                      icon: _vm.ICONS.report,
                      label: "Movement type",
                      "value-prop": "type",
                    },
                  }),
                  _c("ConnectivityCell", {
                    attrs: { cell: _vm.componentData("connectivityComponent") },
                  }),
                  _c("ReachabilityCell", {
                    attrs: { cell: _vm.componentData("reachabilityComponent") },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("HeaderCell", {
                    attrs: {
                      cell: _vm.componentData("mileageComponent"),
                      icon: _vm.ICONS.miles,
                      label: "Last mileage",
                      "value-prop": "mileage_level",
                    },
                  }),
                  _vm.componentData("fuelComponent")
                    ? _c("HeaderCell", {
                        attrs: {
                          cell: _vm.componentData("fuelComponent"),
                          icon: _vm.ICONS.fuelDispenser,
                          label: "Fuel level",
                          "value-prop": "fuel_level",
                        },
                      })
                    : _vm._e(),
                  _vm.shouldRenderStateOfCharge
                    ? _c("HeaderCell", {
                        attrs: {
                          cell: _vm.componentData("stateOfChargeComponent"),
                          icon: _vm.ICONS.electric,
                          tooltip: _vm.TOOLTIPS.stateOfCharge,
                          label: "State of charge",
                          "value-prop": "state_of_charge",
                        },
                      })
                    : _vm._e(),
                  !_vm.shouldRenderStateOfCharge &&
                  !_vm.componentData("fuelComponent")
                    ? _c("NoFuelOrChargeCell")
                    : _vm._e(),
                  _c("HeaderCell", {
                    attrs: {
                      cell: _vm.componentData("batteryComponent"),
                      icon: _vm.ICONS.carBattery,
                      label: "Battery",
                      "value-prop": "level",
                      unit: "V",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "mb-1",
                          attrs: {
                            "data-test-id": "responsible_station-label",
                          },
                        },
                        [_vm._v(" Responsible station ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "emobg-body-1 mb-1",
                          attrs: {
                            "data-test-id": "responsible_station-value",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.stationName) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "emobg-body-1 emobg-color-ink-light",
                          attrs: {
                            "data-test-id": "responsible_station-timestamp",
                          },
                        },
                        [
                          _vm._v(
                            " Updated: " +
                              _vm._s(_vm.getGpsTimeStampFor("station")) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("MapTagComponent", {
                          attrs: {
                            "is-revealed": _vm.isRevealed,
                            "last-update": _vm.getGpsTimeStampFor("vehicle"),
                            "data-test-id": "map_tag",
                          },
                          on: {
                            revealPosition: function ($event) {
                              return _vm.toggleRevealPositionModal()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("GoogleMapZonesComponent", {
                    staticClass: "my-3",
                    attrs: {
                      "marker-sources": _vm.locations,
                      zones: _vm.zones,
                      "data-test-id": "zone-map",
                      "full-screen-control": "",
                      "is-static": "",
                    },
                  }),
                  _c("RevealLocationModal", {
                    attrs: {
                      "is-modal-visible": _vm.isModalVisible,
                      "last-update": _vm.getGpsTimeStampFor("vehicle"),
                      "data-test-id": "reveal_location-modal",
                    },
                    on: {
                      close: _vm.onCancelRevealRequest,
                      reveal: _vm.onRevealPosition,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }