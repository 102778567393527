var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasDeviceInfo
    ? _c(
        "ui-card",
        {
          staticClass: "mb-4 d-block",
          attrs: { header: "Device", "data-test-id": "device-card" },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.deviceInfo, function (value, key) {
              return _c(
                "div",
                { key: key, staticClass: "col-6 col-xl-3 mb-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mb-1 emobg-body-2",
                      attrs: { "data-test-id": `${_vm.snakeCase(key)}-label` },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.includes(key, "Installed")
                              ? "Installation status"
                              : _vm.sentenceCase(key)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.includes(key, "Date") && value
                    ? [
                        _c(
                          "span",
                          {
                            attrs: {
                              "data-test-id": `${_vm.snakeCase(key)}-value`,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatUtc(
                                    value,
                                    _vm.DATE_FORMAT.defaultExtended,
                                    _vm.operatorTimezone
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm.includes(key, "Installed")
                    ? [
                        _c(
                          "span",
                          {
                            attrs: {
                              "data-test-id": `${_vm.snakeCase(key)}-value`,
                            },
                          },
                          [_vm._v(_vm._s(value ? "Installed" : "Uninstalled"))]
                        ),
                      ]
                    : _c(
                        "span",
                        {
                          attrs: {
                            "data-test-id": `${_vm.snakeCase(key)}-value`,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(value || _vm.FALLBACK_MESSAGE.notAvailable)
                          ),
                        ]
                      ),
                ],
                2
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }