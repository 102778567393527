<script>
import get from 'lodash/get';
import find from 'lodash/find';

import { mapActions, mapMutations, mapState } from 'vuex';

import { NOTIFICATION_TYPE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  isNilValue,
  TIME_ZONE,
} from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import { GoogleMapZonesComponent } from '@/components';
import carRental from '@domains/Carrental/store/CarrentalModuleMap';
import MapTagComponent from './MapTagComponent';

import { CARRENTAL_PERMISSIONS } from '../../../const/permissions';
import RevealLocationModal from './RevealLocationModal';
import HeaderCell from './HeaderCell';
import ReachabilityCell from './ReachabilityCell';
import ConnectivityCell from './ConnectivityCell';
import NoFuelOrChargeCell from './NoFuelOrChargeCell.vue';

export default {
  name: 'VehicleSummaryHeaderComponent',
  components: {
    HeaderCell,
    ReachabilityCell,
    ConnectivityCell,
    NoFuelOrChargeCell,
    GoogleMapZonesComponent,
    MapTagComponent,
    RevealLocationModal,
  },
  mixins: [permissionsMixin],
  props: {
    status: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
    data: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      vehicleVin: get(this, '$route.params.vehicleVin'),
      isRevealed: false,
      isModalVisible: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userUuid: state => state.user.data.uuid,
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
      operatorCountryCode: state => state.operators.countryCode,
    }),
    ...mapState(carRental.vehicle.summary, {
      vehicleGpsLocation: state => state.gpsLocation.data,
    }),
    gpsComponentData() {
      return get(this.componentData('gpsComponent'), 'data', {});
    },
    stateOfChargeComponentData() {
      return get(this.componentData('stateOfChargeComponent'), 'data', {});
    },
    stationName() {
      const gwyId = get(this.gpsComponentData, 'station.greenway_id', '');
      const gwyName = get(this.gpsComponentData, 'station.greenway_name', '');

      return (gwyId && gwyName) ? `${gwyId} - ${gwyName}` : FALLBACK_MESSAGE.noData;
    },
    vehicleLocation() {
      const vehicleComponent = get(this.componentData('vehicleComponent'), 'data', {});
      return this.vehicleGpsLocation ? {
        type: 'coordinates',
        latitude: this.vehicleGpsLocation.latitude,
        longitude: this.vehicleGpsLocation.longitude,
        icon: { url: '/carrental/vehicle.svg' },
        uuid: 'vehicle',
        zIndex: 10000,
        tooltip: vehicleComponent.license_plate || '',
      } : {};
    },
    locations() {
      const station = get(this.gpsComponentData, 'station') || {};

      const stationCoordinates = {
        type: 'coordinates',
        latitude: get(station, 'pinpoint.latitude'),
        longitude: get(station, 'pinpoint.longitude'),
        icon: { url: '/carrental/station.svg' },
        uuid: 'station',
        tooltip: station.greenway_name || '',
      };

      return this.isRevealed
        ? [this.vehicleLocation, stationCoordinates]
        : [stationCoordinates];
    },
    zones() {
      const { station } = this.gpsComponentData;

      return get(station, 'geofences') || [];
    },
    gwStatus() {
      return get(this.componentData('greenwayComponent'), 'data.status');
    },
    shouldRenderStateOfCharge() {
      return !isNilValue(get(this.stateOfChargeComponentData, 'state_of_charge', null));
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
    this.TOOLTIPS = {
      stateOfCharge: 'State of charge is the level of an electric vehicle',
    };
  },
  mounted() {
    this.resetGpsLocation();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.vehicle.summary, ['getVehicleGpsLocation']),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
      'removeAllNotifications',
    ]),
    ...mapMutations(carRental.vehicle.summary, [
      'resetGpsLocation',
    ]),
    get,
    componentData(componentKey) {
      return find(this.data || [], { type: componentKey });
    },
    toggleRevealPositionModal() {
      if (this.isRevealed) {
        this.resetGpsLocation();
        this.removeAllNotifications();
        this.$nextTick(() => {
          this.notify({ message: 'Vehicle position hidden.', notificationType: NOTIFICATION_TYPE.info });
        });
      }

      this.isModalVisible = !this.isRevealed;
      this.isRevealed = false;
    },
    onRevealPosition(reason) {
      if (!reason) {
        return;
      }

      this.isRevealed = true;
      this.isModalVisible = false;

      this.removeAllNotifications();
      this.$nextTick(() => {
        this.notify({ message: 'Vehicle position shown.' });
      });

      this.getVehicleGpsLocation({
        vehicleVin: this.vehicleVin,
        query: {
          reason,
          userId: this.userUuid,
          countryCode: this.operatorCountryCode,
        },
      });
    },
    onCancelRevealRequest() {
      this.isModalVisible = false;
    },
    getGpsTimeStampFor(type) {
      const timestamp = get(this.gpsComponentData, `${type}.timestamp`);
      return timestamp
        ? formatUtc(timestamp, this.DATE_FORMAT.defaultExtended, this.operatorTimezone)
        : this.FALLBACK_MESSAGE.noData;
    },
  },
};
</script>

<template>
  <div
    class="VehicleSummaryHeaderComponent"
    data-test-id="vehicle_summary_header"
  >
    <ui-loader v-if="status.LOADING" />
    <template v-else>
      <div class="row">
        <div class="col">
          <div
            v-if="componentData('vehicleComponent')"
            class="emobg-color-ink mb-3"
          >
            <h2
              class="mb-1"
              data-test-id="plate_number"
            >
              {{ get(componentData('vehicleComponent'),'data.license_plate', FALLBACK_MESSAGE.notAvailable) }}
            </h2>
            <h3
              class="mb-1"
              data-test-id="model"
            >
              {{ get(componentData('vehicleComponent'), 'data.model', FALLBACK_MESSAGE.notAvailable) }}
            </h3>
            <h3
              class="emobg-color-ink-light"
              data-test-id="category"
            >
              {{ get(componentData('vehicleComponent'), 'data.category', FALLBACK_MESSAGE.notAvailable) }}
            </h3>
          </div>
          <HeaderCell
            :cell="componentData('greenwayComponent')"
            :icon="ICONS.window"
            label="GWY status"
            value-prop="status"
          />
          <HeaderCell
            :cell="componentData('movementComponent')"
            :icon="ICONS.report"
            label="Movement type"
            value-prop="type"
          />
          <ConnectivityCell :cell="componentData('connectivityComponent')" />
          <ReachabilityCell :cell="componentData('reachabilityComponent')" />
        </div>
        <div class="col">
          <HeaderCell
            :cell="componentData('mileageComponent')"
            :icon="ICONS.miles"
            label="Last mileage"
            value-prop="mileage_level"
          />
          <HeaderCell
            v-if="componentData('fuelComponent')"
            :cell="componentData('fuelComponent')"
            :icon="ICONS.fuelDispenser"
            label="Fuel level"
            value-prop="fuel_level"
          />
          <HeaderCell
            v-if="shouldRenderStateOfCharge"
            :cell="componentData('stateOfChargeComponent')"
            :icon="ICONS.electric"
            :tooltip="TOOLTIPS.stateOfCharge"
            label="State of charge"
            value-prop="state_of_charge"
          />
          <NoFuelOrChargeCell v-if="!shouldRenderStateOfCharge && !componentData('fuelComponent')" />
          <HeaderCell
            :cell="componentData('batteryComponent')"
            :icon="ICONS.carBattery"
            label="Battery"
            value-prop="level"
            unit="V"
          />
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col">
              <h3
                class="mb-1"
                data-test-id="responsible_station-label"
              >
                Responsible station
              </h3>
              <div
                class="emobg-body-1 mb-1"
                data-test-id="responsible_station-value"
              >
                {{ stationName }}
              </div>
              <div
                class="emobg-body-1 emobg-color-ink-light"
                data-test-id="responsible_station-timestamp"
              >
                Updated: {{ getGpsTimeStampFor('station') }}
              </div>
            </div>
            <div class="col">
              <MapTagComponent
                :is-revealed="isRevealed"
                :last-update="getGpsTimeStampFor('vehicle')"
                data-test-id="map_tag"
                @revealPosition="toggleRevealPositionModal()"
              />
            </div>
          </div>
          <GoogleMapZonesComponent
            :marker-sources="locations"
            :zones="zones"
            data-test-id="zone-map"
            full-screen-control
            is-static
            class="my-3"
          />
          <RevealLocationModal
            :is-modal-visible="isModalVisible"
            :last-update="getGpsTimeStampFor('vehicle')"
            data-test-id="reveal_location-modal"
            @close="onCancelRevealRequest"
            @reveal="onRevealPosition"
          />
        </div>
      </div>
    </template>
  </div>
</template>
