<script>
import snakeCase from 'lodash/snakeCase';
import includes from 'lodash/includes';
import pickBy from 'lodash/pickBy';

import { mapState } from 'vuex';

import { DATE_FORMAT, TIME_ZONE } from '@emobg/web-utils';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import carRental from '../../../store/CarrentalModuleMap';
import { mileageUnitMixin } from '../../../mixins';
import { CARRENTAL_PERMISSIONS } from '../../../const/permissions';
import VehicleDescriptionCard from './components/VehicleDescriptionCard.vue';
import VehicleFinancialInfoCard from './components/VehicleFinancialInfoCard.vue';
import VehicleDeviceCard from './components/VehicleDeviceCard.vue';
import { DESCRIPTION_INFO_KEYS, FINANCIAL_INFO_KEYS } from './constants/vehicleInfo.const';

export default {
  name: 'SummaryInfoView',
  components: { VehicleDeviceCard, VehicleDescriptionCard, VehicleFinancialInfoCard },
  mixins: [mileageUnitMixin, permissionsMixin],
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(carRental.vehicle.info, {
      infoStatus: state => state.basicInfo.STATUS,
      descriptionInfo: state => pickBy(state.basicInfo.data, (_, key) => DESCRIPTION_INFO_KEYS.includes(key)),
      financialInfo: state => pickBy(state.basicInfo.data, (_, key) => FINANCIAL_INFO_KEYS.includes(key)),
      deviceInfo: state => state.basicInfo.data.installedDevice || {},
    }),
  },
  created() {
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    snakeCase,
    includes,
  },
};
</script>

<template>
  <div
    class="SummaryInfoView"
    data-test-id="summary_info-view"
  >
    <ui-loader v-if="infoStatus.LOADING" />
    <div
      v-else
      class="row"
    >
      <div class="col">
        <VehicleDescriptionCard :info="descriptionInfo" />
        <VehicleFinancialInfoCard :info="financialInfo" />
        <VehicleDeviceCard :device-info="deviceInfo" />
      </div>
    </div>
  </div>
</template>
