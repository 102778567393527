var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "HeaderCell mb-3" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center mb-1" },
      [
        _c(
          "h3",
          { attrs: { "data-test-id": `${_vm.snakeCase(_vm.label)}-label` } },
          [_vm._v(" " + _vm._s(_vm.label) + " ")]
        ),
        _vm.tooltip
          ? _c(
              "ui-tooltip",
              { attrs: { tooltip: _vm.tooltip } },
              [
                _c("ui-icon", {
                  staticClass: "ml-1",
                  attrs: {
                    color: _vm.GRAYSCALE.inkLight,
                    size: _vm.ICONS_SIZES.small,
                    icon: _vm.ICONS.infoFull,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info-box mb-1" },
      [
        _vm.icon
          ? _c("ui-icon", {
              staticClass: "mr-2 d-inline-block emobg-color-black",
              attrs: { size: _vm.ICONS_SIZES.large, icon: _vm.icon },
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "d-flex emobg-body-1",
            attrs: { "data-test-id": `${_vm.snakeCase(_vm.label)}-value` },
          },
          [
            _vm.valueProp === "level"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.value
                          ? `${_vm.value} ${_vm.unit}`
                          : _vm.FALLBACK_MESSAGE.noData
                      ) +
                      " "
                  ),
                ])
              : _vm.valueProp === "fuel_level" ||
                _vm.valueProp === "state_of_charge"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isNil(_vm.value)
                          ? _vm.FALLBACK_MESSAGE.noData
                          : `${_vm.value} %`
                      ) +
                      " "
                  ),
                ])
              : _vm.valueProp === "mileage_level"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.value
                          ? _vm.formatDistance(_vm.value, _vm.mileageUnit, 0)
                          : _vm.FALLBACK_MESSAGE.noData
                      ) +
                      " "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.value || _vm.FALLBACK_MESSAGE.noData) + " "
                  ),
                ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "span",
      {
        staticClass: "emobg-body-1 emobg-color-ink-light",
        attrs: { "data-test-id": `${_vm.snakeCase(_vm.label)}-timestamp` },
      },
      [
        _vm._v(
          " Updated: " +
            _vm._s(
              _vm.timeStamp
                ? _vm.formatUtc(
                    _vm.timeStamp,
                    _vm.DATE_FORMAT.defaultExtended,
                    _vm.operatorTimezone
                  )
                : _vm.FALLBACK_MESSAGE.noData
            ) +
            " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }