var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SummaryMovementsView emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small px-3 py-4",
      attrs: { "data-test-id": "summary_movements-view" },
    },
    [
      _c(
        "h1",
        {
          staticClass:
            "pb-3 mb-2 emobg-border-bottom-2 emobg-border-color-ground-light",
        },
        [_vm._v(" Movements ")]
      ),
      _c(
        "MuiApiList",
        {
          attrs: {
            "data-set": _vm.movementsList,
            "content-cells": _vm.contentCells,
            "is-loading": _vm.isLoading,
            "active-filters-count": _vm.activeFiltersCount,
            "external-pagination": true,
            "page-size": _vm.MOVEMENTS_ITEMS_PER_PAGE,
            "total-records": _vm.totalPages * _vm.MOVEMENTS_ITEMS_PER_PAGE,
            "no-data-label": _vm.FALLBACK_MESSAGE.noData,
            "no-border": true,
            "data-test-id": "list",
          },
          on: {
            "update:page": (page) => (_vm.pageNumber = page.currentPage),
            "update:isMobileLayout": (isMobile) =>
              (_vm.isMobileLayout = isMobile),
            "update:clearFilters": _vm.resetFilters,
          },
        },
        [
          _c("Filters", {
            attrs: {
              slot: "customFilters",
              "is-mobile-layout": _vm.isMobileLayout,
              clear: !_vm.activeFiltersCount,
            },
            on: {
              "on:filterChanged": ({ filterData, filterKey }) =>
                (_vm.filters[filterKey] = filterData),
            },
            slot: "customFilters",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }