import round from 'lodash/round';
import { formatThousands } from '@emobg/web-utils';

export const CHART_TYPES = Object.freeze({
  fuel: 'fuel',
  stateOfCharge: 'stateOfCharge',
  mileage: 'mileage',
  battery: 'battery',
});

export const getChartsConfig = mileageUnit => ({
  fuel: {
    label: 'Fuel level',
    unit: '%',
    color: '#800000',
    id: 'fuel',
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: fuelLevel => `${fuelLevel} %`,
      },
    },
  },
  stateOfCharge: {
    label: 'State of charge',
    unit: '%',
    color: '#800000',
    id: 'stateOfCharge',
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: stateOfCharge => `${stateOfCharge} %`,
      },
    },
  },
  mileage: {
    label: 'Mileage',
    unit: mileageUnit,
    color: '#4682B4',
    id: 'mileage',
    yaxis: {
      labels: {
        formatter: mileage => `${formatThousands(mileage)} ${mileageUnit}`,
      },
    },
  },
  battery: {
    label: 'Battery level',
    unit: 'V',
    color: '#2E8B57',
    id: 'battery',
    yaxis: {
      min: 0,
      labels: {
        min: 0,
        forceNiceScale: true,
        formatter: batteryCharge => `${round(batteryCharge, 2)} V`,
      },
    },
  },
});
