<script>
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';

import { mapState } from 'vuex';
import {
  DATE_FORMAT,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'InfoSection',
  props: {
    title: {
      type: String,
      default: '',
    },
    textValue: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    isForPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    formatUtc,
    snakeCase,
  },
};
</script>

<template>
  <div
    class="InfoSection mb-3"
    data-test-id="info_section-component"
  >
    <h4
      :data-test-id="`${snakeCase(title)}_title`"
      class="emobg-font-weight-bold emobg-font-small mb-2"
    >
      {{ title }}
    </h4>
    <div
      :data-test-id="`${snakeCase(title)}_value`"
      class="emobg-font-small mb-1"
    >
      {{ textValue }}
    </div>
    <div
      v-if="!isForPdf"
      :data-test-id="`${snakeCase(title)}_date`"
      class="emobg-font-weight-light emobg-font-small emobg-color-ink-light"
    >
      Updated: {{ formatUtc(date, DATE_FORMAT.defaultExtended, operatorTimezone) }}
    </div>
  </div>
</template>
