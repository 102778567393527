<script>
export default {
  name: 'MovementIdentifier',
  props: {
    movementNumber: {
      type: String,
      default: '',
    },
    movementType: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="MovementIdentifier row">
    <div class="col-6 px-3">
      {{ movementNumber || FALLBACK_MESSAGE.dash }}
    </div>
    <div class="col-6 px-3">
      {{ movementType || FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
