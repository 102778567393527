<script>
import get from 'lodash/get';
import includes from 'lodash/includes';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import { CARRENTAL_PERMISSIONS } from '../../../const/permissions';

export default {
  name: 'MapTagComponent',
  mixins: [permissionsMixin],
  props: {
    isRevealed: {
      type: Boolean,
      default: null,
    },
    lastUpdate: {
      type: String,
      default: '',
    },
  },
  created() {
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
  },
  methods: {
    get,
    includes,
    toggleIsRevealed() {
      this.$emit('revealPosition');
    },
  },
};
</script>

<template>
  <div
    class="MapTagComponent"
    data-test-id="map-tag"
  >
    <h3
      class="emobg-font-weight-semibold mb-1"
      data-test-id="vehicle_position-label"
    >
      Vehicle position
    </h3>
    <div v-if="includes(permissions, CARRENTAL_PERMISSIONS.vehicleShowPosition)">
      <ui-button
        :face="FACES.outline"
        class="d-block mb-1"
        data-test-id="location-toggle"
        @clickbutton="toggleIsRevealed"
      >
        <div class="d-flex align-items-center">
          <ui-icon
            :icon="isRevealed ? ICONS.hide : ICONS.show"
            data-test-id="reveal_hide-status"
          />
          <div class="ml-2 emobg-font-small">
            {{ isRevealed ? 'Hide position' : 'Show position' }}
          </div>
        </div>
      </ui-button>
      <span
        class="emobg-body-1 emobg-color-ink-light"
        data-test-id="vehicle_position-timestamp"
      >
        Updated: {{ lastUpdate }}
      </span>
    </div>
    <ui-alert
      v-else
      :color="COLORS.primary"
      :icon="ICONS.infoFull"
      data-test-id="vehicle_position-no_permissions-alert"
    >
      <p class="emobg-body-1 emobg-color-ink">
        You don’t have permissions to view the vehicle location map
      </p>
    </ui-alert>
  </div>
</template>
