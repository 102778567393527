<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { DATE_FORMAT, formatUtc, TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getDateDiff } from '../../utils/dateDiff';
import { LABELS } from '../../const/labels.const';

export default {
  name: 'ReachabilityCell',
  props: {
    cell: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    timeStamp() {
      return get(this.cell, 'data.timestamp');
    },
    reachabilityInfo() {
      if (this.timeStamp) {
        return getDateDiff(this.timeStamp);
      }

      return LABELS.neverReceived;
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    formatUtc,
  },
};
</script>
<template>
  <div class="RechabilityCell mb-3">
    <h3
      class="mb-1"
      data-test-id="data_last_received-label"
    >
      Data last received
    </h3>
    <div class="info-box mb-1">
      <ui-icon
        :size="ICONS_SIZES.large"
        :icon="ICONS.connectivity"
        class="mr-2 d-inline-block emobg-color-black"
      />
      <div
        class="d-flex emobg-body-1"
        data-test-id="data_last_received-value"
      >
        {{ reachabilityInfo }}
      </div>
    </div>
    <span
      class="emobg-font-small emobg-font-weight-normal emobg-color-ink-light"
      data-test-id="data_last_received-timestamp"
    >
      Last received:
      {{ timeStamp ?
        formatUtc(timeStamp, DATE_FORMAT.defaultExtended, operatorTimezone) :
        FALLBACK_MESSAGE.noData
      }}
    </span>
  </div>
</template>
