var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stationId && _vm.stationUuid
    ? _c(
        "div",
        [
          _c(
            "RouterLink",
            {
              staticClass: "emobg-link-primary emobg-body-2",
              attrs: {
                to: {
                  name: _vm.carrental.vehicles.stations.details,
                  params: { stationUuid: _vm.stationUuid },
                },
                "data-test-id": `station-link-${_vm.stationUuid}`,
              },
            },
            [_vm._v(" " + _vm._s(_vm.stationId) + " ")]
          ),
        ],
        1
      )
    : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }