<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { DATE_FORMAT, formatUtc, TIME_ZONE } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'StationDateTime',
  props: {
    station: {
      type: String,
      default: '',
    },
    dateTime: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    formatUtc,
  },
};
</script>

<template>
  <div class="StationDateTime row">
    <div class="col-6 px-3">
      {{ station || FALLBACK_MESSAGE.dash }}
    </div>
    <div class="col-6 px-3">
      {{ dateTime ? formatUtc(dateTime, DATE_FORMAT.defaultExtended, operatorTimezone) : FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
