<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import last from 'lodash/last';
import round from 'lodash/round';

import { mapState } from 'vuex';
import {
  DATE_FORMAT,
  DELIMITERS,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { mileageUnitMixin } from '../../../../mixins';
import { TELEMETRY_KEYS } from '../const/telemetry.const';
import InfoSection from './InfoSection.vue';

export default {
  name: 'TelemetrySummary',
  components: {
    InfoSection,
  },
  mixins: [mileageUnitMixin],
  props: {
    queryDates: {
      type: Object,
      default: () => ({}),
    },
    selectedTelemetry: {
      type: Object,
      default: () => ({}),
    },
    isForPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carRental.vehicle.telemetry, {
      fuelData: state => get(state, 'telemetry.data.fuel') || [],
      stateOfChargeData: state => get(state, 'telemetry.data.stateOfCharge') || [],
      mileageData: state => get(state, 'telemetry.data.mileage') || [],
      batteryData: state => get(state, 'telemetry.data.batteryLevel') || [],
    }),
    fuelInfo() {
      return isEmpty(this.fuelData) ? {} : {
        from: {
          value: `${last(this.fuelData).value} %`,
          date: last(this.fuelData).occurredOn,
        },
        to: {
          value: `${first(this.fuelData).value} %`,
          date: first(this.fuelData).occurredOn,
        },
      };
    },
    stateOfChargeInfo() {
      return isEmpty(this.stateOfChargeData) ? {} : {
        from: {
          value: `${last(this.stateOfChargeData).value} %`,
          date: last(this.stateOfChargeData).occurredOn,
        },
        to: {
          value: `${first(this.stateOfChargeData).value} %`,
          date: first(this.stateOfChargeData).occurredOn,
        },
      };
    },
    mileageInfo() {
      return isEmpty(this.mileageData) ? {} : {
        from: {
          value: this.formatDistance(last(this.mileageData).value, this.mileageUnit, 0),
          date: last(this.mileageData).occurredOn,
        },
        to: {
          value: `${this.formatDistance(first(this.mileageData).value, this.mileageUnit, 0)} ${this.getDistanceDiffLabel()}`,
          date: first(this.mileageData).occurredOn,
        },
      };
    },
    batteryInfo() {
      return isEmpty(this.batteryData) ? {} : {
        from: {
          value: `${round(last(this.batteryData).value, 2)} V`,
          date: last(this.batteryData).occurredOn,
        },
        to: {
          value: `${round(first(this.batteryData).value, 2)} V`,
          date: first(this.batteryData).occurredOn,
        },
      };
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.TIME_ZONE = TIME_ZONE;
    this.TELEMETRY_KEYS = TELEMETRY_KEYS;
  },
  methods: {
    getDistanceDiffLabel() {
      let distanceLabel = '';

      if (!isEmpty(this.mileageData)) {
        const distanceDifference = first(this.mileageData).value - last(this.mileageData).value;
        if (!distanceDifference) {
          return '';
        }
        distanceLabel = `(${distanceDifference > 0 ? DELIMITERS.plus : DELIMITERS.dash} ${this.formatDistance(Math.abs(distanceDifference), this.mileageUnit, 0)})`;
      }

      return distanceLabel;
    },
    isEmpty,
    formatUtc,
  },
};
</script>

<template>
  <div class="TelemetrySummary row">
    <div
      class="col-6"
      data-test-id="data_from"
    >
      <div class="mb-3 pb-1 emobg-border-bottom-1 emobg-border-color-ground-light">
        <h3 class="emobg-font-weight-bold emobg-font-default mb-1">
          {{ isForPdf ? 'Data from (Pick up)' : 'Data available from' }}
        </h3>
        <span class="emobg-font-small">
          {{ formatUtc(queryDates.from, DATE_FORMAT.defaultExtended, operatorTimezone) }}
        </span>
      </div>
      <InfoSection
        v-if="!isEmpty(mileageInfo) && selectedTelemetry[TELEMETRY_KEYS.mileage]"
        :text-value="mileageInfo.from.value"
        :date="mileageInfo.from.date"
        :is-for-pdf="isForPdf"
        title="Mileage"
      />
      <InfoSection
        v-if="!isEmpty(fuelInfo) && selectedTelemetry[TELEMETRY_KEYS.fuel]"
        :text-value="fuelInfo.from.value"
        :date="fuelInfo.from.date"
        :is-for-pdf="isForPdf"
        title="Fuel level"
      />
      <InfoSection
        v-if="!isEmpty(stateOfChargeInfo) && selectedTelemetry[TELEMETRY_KEYS.stateOfCharge]"
        :text-value="stateOfChargeInfo.from.value"
        :date="stateOfChargeInfo.from.date"
        :is-for-pdf="isForPdf"
        title="State of charge"
      />
      <InfoSection
        v-if="!isEmpty(batteryInfo) && selectedTelemetry[TELEMETRY_KEYS.batteryLevel]"
        :text-value="batteryInfo.from.value"
        :date="batteryInfo.from.date"
        :is-for-pdf="isForPdf"
        title="Battery"
      />
    </div>
    <div
      class="col-6"
      data-test-id="data_to"
    >
      <div class="mb-3 pb-1 emobg-border-bottom-1 emobg-border-color-ground-light">
        <h3 class="emobg-font-weight-bold emobg-font-default mb-1">
          {{ isForPdf ? 'Data to (Return)' : 'Data available to' }}
        </h3>
        <span class="emobg-font-small">
          {{ formatUtc(queryDates.to, DATE_FORMAT.defaultExtended, operatorTimezone) }}
        </span>
      </div>
      <InfoSection
        v-if="!isEmpty(mileageInfo) && selectedTelemetry[TELEMETRY_KEYS.mileage]"
        :text-value="mileageInfo.to.value"
        :date="mileageInfo.to.date"
        :is-for-pdf="isForPdf"
        title="Mileage"
      />
      <InfoSection
        v-if="!isEmpty(fuelInfo) && selectedTelemetry[TELEMETRY_KEYS.fuel]"
        :text-value="fuelInfo.to.value"
        :date="fuelInfo.to.date"
        :is-for-pdf="isForPdf"
        title="Fuel level"
      />
      <InfoSection
        v-if="!isEmpty(stateOfChargeInfo) && selectedTelemetry[TELEMETRY_KEYS.stateOfCharge]"
        :text-value="stateOfChargeInfo.to.value"
        :date="stateOfChargeInfo.to.date"
        :is-for-pdf="isForPdf"
        title="State of charge"
      />
      <InfoSection
        v-if="!isEmpty(batteryInfo) && selectedTelemetry[TELEMETRY_KEYS.batteryLevel]"
        :text-value="batteryInfo.to.value"
        :date="batteryInfo.to.date"
        :is-for-pdf="isForPdf"
        title="Battery"
      />
    </div>
  </div>
</template>
