<script>
import get from 'lodash/get';

import { mapActions, mapState } from 'vuex';
import { MuiApiList } from '@emobg/vue-internal';
import { TIME_ZONE } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { contentCells } from './config/stationHistoryContentCells';
import { STATION_HISTORY_ITEMS_PER_PAGE } from './const/stationHistory.const';

export default {
  name: 'SummaryStationHistoryView',
  components: {
    MuiApiList,
  },
  data() {
    return {
      pageNumber: 0,
      isMobileLayout: true,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carRental.vehicle.summary, {
      isLoading: state => state.stationGeofenceHistory.STATUS.LOADING,
      stationHistoryList: state => get(state, 'stationGeofenceHistory.data.geofencingEvents') || [],
      totalPages: state => get(state, 'stationGeofenceHistory.data.pageCount') || 1,
    }),
    queryData() {
      return {
        pageNumber: this.pageNumber,
        pageSize: STATION_HISTORY_ITEMS_PER_PAGE,
      };
    },
  },
  watch: {
    pageNumber: {
      handler() {
        this.getStationGeofenceHistory({
          vehicleVin: this.vehicleVin,
          data: this.queryData,
        });
      },
      immediate: true,
    },
    activeOperatorUuid() {
      this.pageNumber = 0;
    },
  },
  beforeCreate() {
    this.vehicleVin = this.$route.params.vehicleVin;
    this.STATION_HISTORY_ITEMS_PER_PAGE = STATION_HISTORY_ITEMS_PER_PAGE;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.vehicle.summary, [
      'getStationGeofenceHistory',
    ]),
    contentCells,
  },
};
</script>

<template>
  <div
    class="SummaryStationHistoryView"
    data-test-id="summary_status_history-view"
  >
    <ui-card
      header="Station Geofence"
      description="After 30 days of the cross the data will be deleted to follow our data protection policy"
    >
      <MuiApiList
        :data-set="stationHistoryList"
        :content-cells="contentCells({ operatorTimezone })"
        :is-loading="isLoading"
        :external-pagination="true"
        :page-size="STATION_HISTORY_ITEMS_PER_PAGE"
        :total-records="(totalPages * STATION_HISTORY_ITEMS_PER_PAGE)"
        :no-data-label="FALLBACK_MESSAGE.noData"
        :no-border="true"
        :no-scroll-top="true"
        data-test-id="list"
        @update:page="(page) => pageNumber = page.currentPage"
        @update:isMobileLayout="(isMobile) => isMobileLayout = isMobile"
      />
    </ui-card>
  </div>
</template>
