var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "DoubleHeader" }, [
    _c(
      "div",
      {
        staticClass:
          "row py-3 emobg-border-right-1 emobg-border-bottom-1 emobg-border-color-ink-lighter",
      },
      [
        _c("div", { staticClass: "col-12 d-flex justify-content-center" }, [
          _vm._v(" " + _vm._s(_vm.mainHeaderText) + " "),
        ]),
      ]
    ),
    _c("div", { staticClass: "row emobg-background-color-ground-lighter" }, [
      _c(
        "div",
        {
          staticClass:
            "col-6 p-3 emobg-border-right-1 emobg-border-color-ink-lighter",
        },
        [_vm._v(" " + _vm._s(_vm.subHeaderLeft) + " ")]
      ),
      _c(
        "div",
        {
          staticClass:
            "col-6 p-3 emobg-border-right-1 emobg-border-color-ink-lighter",
        },
        [_vm._v(" " + _vm._s(_vm.subHeaderRight) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }