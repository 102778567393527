var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "SummaryStationHistoryView",
      attrs: { "data-test-id": "summary_status_history-view" },
    },
    [
      _c(
        "ui-card",
        {
          attrs: {
            header: "Station Geofence",
            description:
              "After 30 days of the cross the data will be deleted to follow our data protection policy",
          },
        },
        [
          _c("MuiApiList", {
            attrs: {
              "data-set": _vm.stationHistoryList,
              "content-cells": _vm.contentCells({
                operatorTimezone: _vm.operatorTimezone,
              }),
              "is-loading": _vm.isLoading,
              "external-pagination": true,
              "page-size": _vm.STATION_HISTORY_ITEMS_PER_PAGE,
              "total-records":
                _vm.totalPages * _vm.STATION_HISTORY_ITEMS_PER_PAGE,
              "no-data-label": _vm.FALLBACK_MESSAGE.noData,
              "no-border": true,
              "no-scroll-top": true,
              "data-test-id": "list",
            },
            on: {
              "update:page": (page) => (_vm.pageNumber = page.currentPage),
              "update:isMobileLayout": (isMobile) =>
                (_vm.isMobileLayout = isMobile),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }