<script>
export default {
  name: 'DoubleHeader',
  props: {
    mainHeaderText: {
      type: String,
      default: '',
    },
    subHeaderLeft: {
      type: String,
      default: '',
    },
    subHeaderRight: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="DoubleHeader">
    <div class="row py-3 emobg-border-right-1 emobg-border-bottom-1 emobg-border-color-ink-lighter">
      <div class="col-12 d-flex justify-content-center">
        {{ mainHeaderText }}
      </div>
    </div>
    <div class="row emobg-background-color-ground-lighter">
      <div class="col-6 p-3 emobg-border-right-1 emobg-border-color-ink-lighter">
        {{ subHeaderLeft }}
      </div>
      <div class="col-6 p-3 emobg-border-right-1 emobg-border-color-ink-lighter">
        {{ subHeaderRight }}
      </div>
    </div>
  </div>
</template>
