var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "SummaryEuropcarView",
      attrs: { "data-test-id": "summary_europcar-view" },
    },
    [
      _c(
        "ui-card",
        {
          staticClass: "d-block mb-1",
          attrs: {
            header: "Greenway status history",
            "data-test-id": "gwy-card",
          },
        },
        [
          _c("MuiTable", {
            staticClass: "mx-0",
            attrs: {
              "data-set": _vm.gwStatusDataOrdered,
              "content-cells": _vm.contentCells({
                operatorTimezone: _vm.operatorTimezone,
              }),
              "no-data-label": _vm.FALLBACK_MESSAGE.noData,
              "is-loading": _vm.isLoading,
              "page-size": 20,
              "data-test-id": "table",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }