var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "StationDateTime row" }, [
    _c("div", { staticClass: "col-6 px-3" }, [
      _vm._v(" " + _vm._s(_vm.station || _vm.FALLBACK_MESSAGE.dash) + " "),
    ]),
    _c("div", { staticClass: "col-6 px-3" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.dateTime
              ? _vm.formatUtc(
                  _vm.dateTime,
                  _vm.DATE_FORMAT.defaultExtended,
                  _vm.operatorTimezone
                )
              : _vm.FALLBACK_MESSAGE.dash
          ) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }