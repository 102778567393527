var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasInfo
    ? _c(
        "ui-card",
        {
          staticClass: "mb-4 d-block",
          attrs: {
            header: _vm.headerText,
            "data-test-id": `${_vm.snakeCase(_vm.headerText)}-card`,
            rounded: "",
          },
        },
        [
          _vm.info
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.DESCRIPTION_INFO_KEYS, function (key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "col-6 col-xl-3 mb-5" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-1 emobg-font-weight-bold",
                          attrs: {
                            "data-test-id": `${_vm.snakeCase(key)}-label`,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.PROPERTY_TO_LABEL_TEXT[key] ||
                                  _vm.sentenceCase(key)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          attrs: {
                            "data-test-id": `${_vm.snakeCase(key)}-value`,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.info[key] || _vm.FALLBACK_MESSAGE.notAvailable
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c(
                "div",
                { attrs: { "data-test-id": "no_description_info_data-label" } },
                [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.noData) + " ")]
              ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }