<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';

import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  DAYS_IN_WEEK,
  DELAY,
  formatUtc,
  TIME_ZONE,
  toQueryString,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { downloadPdf } from '@/domains/Carrental/utils/downloadPdf';
import {
  TELEMETRY_KEYS,
  TELEMETRY_OPTIONS,
  TELEMETRY_PDF_ID,
  TELEMETRY_SORT_OPTIONS,
} from './const/telemetry.const';
import { TELEMETRY_SETTINGS } from './const/periods.const';
import { mileageUnitMixin } from '../../../mixins';
import { scopes } from './store/VehicleTelemetryModule';
import { DATE_SCOPES } from '../const/date.const';
import carRental from '../../../store/CarrentalModuleMap';

import TelemetrySummary from './components/TelemetrySummary.vue';
import TelemetryPdf from './components/TelemetryPdf.vue';
import TelemetryCharts from './components/TelemetryCharts.vue';

export default {
  name: 'SummaryTelemetryView',
  components: {
    TelemetrySummary,
    TelemetryPdf,
    TelemetryCharts,
  },
  mixins: [mileageUnitMixin],
  data() {
    return {
      isFromBeforeTo: undefined,
      isDurationValid: undefined,
      isWithinMaxPeriod: undefined,
      isDownloading: false,
      hasFrom: false,
      hasTo: false,
      fromDate: '',
      toDate: '',
      queryDates: {
        from: '',
        to: '',
      },
      changedByRANumber: {
        from: false,
        to: false,
      },
      selectedMovementId: '',
      calendarDateRange: {
        start: moment().subtract(TELEMETRY_SETTINGS.maxDays, 'days'),
        end: moment(),
      },
      selectedTelemetry: {
        [TELEMETRY_KEYS.mileage]: true,
        [TELEMETRY_KEYS.fuel]: true,
        [TELEMETRY_KEYS.stateOfCharge]: true,
        [TELEMETRY_KEYS.batteryLevel]: false,
      },
    };
  },
  computed: {
    ...mapState(carRental.vehicle.telemetry, {
      isLoading: state => state.telemetry.STATUS.LOADING,
      telemetryData: state => get(state, 'telemetry.data') || {},
    }),
    ...mapState(DOMAINS_MODEL.carRental.vehicle.summary, {
      summaryComponents: state => get(state, 'summary.data.components') || {},
      movements: state => get(state, 'movements.data') || [],
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
      features: state => get(state, 'features.data'),
    }),
    movementsOptions() {
      const options = map(this.movements, (movement) => ({
        label: movement.rentalAgreementNumber,
        value: movement.id,
      }));

      return sortBy(filter(options, opt => opt.label), ['label']);
    },
    vehicleLicensePlate() {
      const vehicleComponent = find(this.summaryComponents, { type: 'vehicleComponent' }) || {};
      return get(vehicleComponent, 'data.license_plate', '');
    },
    endValidDateRange() {
      if (!this.queryDates.from) {
        return null;
      }

      const startDate = moment(this.queryDates.from);
      const endDate = moment(startDate).add(TELEMETRY_SETTINGS.maxPeriod, 'days');
      return {
        start: startDate,
        end: endDate.isSameOrBefore(moment()) ? endDate : moment(),
      };
    },
    areQueryDatesInRange() {
      return this.isFromBeforeTo && this.isDurationValid && this.isWithinMaxPeriod;
    },
    telemetryAvailableOptions() {
      return reduce(this.telemetryData, (availableOptions, optionValue, optionKey) => {
        if (!isEmpty(optionValue)) {
          availableOptions.push(optionKey);
        }
        return sortBy(availableOptions, telemetryOption => TELEMETRY_SORT_OPTIONS[telemetryOption] || 0);
      }, []);
    },
    isTelemetryEmpty() {
      return isEmpty(this.telemetryAvailableOptions);
    },
    hasSelectedTelemetry() {
      return some(this.selectedTelemetry);
    },
  },
  watch: {
    queryDates: {
      handler() {
        this.validateAndRequest();
      },
      immediate: true,
      deep: true,
    },
    activeOperatorUuid() {
      this.validateAndRequest();
      this.requestTelemetry();
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.TIME_ZONE = TIME_ZONE;
    this.DATE_SCOPES = DATE_SCOPES;
    this.TELEMETRY_SETTINGS = TELEMETRY_SETTINGS;
    this.TELEMETRY_OPTIONS = TELEMETRY_OPTIONS;
    this.TELEMETRY_KEYS = TELEMETRY_KEYS;
    this.vehicleVin = get(this, '$route.params.vehicleVin');
    this.fromDate = moment.tz(this.operatorTimezone).subtract(DAYS_IN_WEEK, 'd');
    this.toDate = moment.tz(this.operatorTimezone);
  },
  mounted() {
    this.getVehicleMovements({
      vehicleVin: this.vehicleVin,
      data: {
        endTimeAfter: moment().subtract(TELEMETRY_SETTINGS.maxDays, 'days').format('X'),
      },
    });
  },
  methods: {
    ...mapActions(carRental.vehicle.telemetry, [
      'getVehicleTelemetry',
    ]),
    ...mapActions(DOMAINS_MODEL.carRental.vehicle.summary, [
      'getVehicleMovements',
    ]),
    ...mapMutations(carRental.vehicle.telemetry, [
      'clearData',
    ]),
    validateAndRequest() {
      const queryFrom = moment.tz(this.queryDates.from, this.operatorTimezone);
      const queryTo = moment.tz(this.queryDates.to, this.operatorTimezone);

      if (!queryFrom.isSame(this.fromDate)) {
        this.fromDate = queryFrom;
        this.changedByRANumber.from = true;
      }
      if (!queryTo.isSame(this.toDate)) {
        this.toDate = queryTo;
        this.changedByRANumber.from = true;
      }
      this.isFromBeforeTo = queryFrom.isSameOrBefore(queryTo);
      this.isDurationValid = Math.abs(moment.duration(queryFrom.diff(queryTo)).asDays()) <= TELEMETRY_SETTINGS.maxPeriod;
      this.isWithinMaxPeriod = this.calendarDateRange.start.isSameOrBefore(queryFrom);
      this.$nextTick(() => {
        if (this.areQueryDatesInRange) {
          this.requestTelemetry();
        } else {
          this.clearData({ scopes: [scopes.telemetry] });
        }
      });
    },
    requestTelemetry() {
      const query = {
        ...this.queryDates,
        types: 'mileage,batteryLevel,fuelLevel,stateOfCharge',
      };
      this.getVehicleTelemetry({ vehicleVin: this.vehicleVin, query: toQueryString(query) });
    },
    /**
     * This method is executed when manually the datepicker is changed OR when we programatically set fromDate or toDate that are the prop variables.
     * That's why we rely on changedByRANumber because ONLY when manually the datepicker is changed we have to reset the value of the select of RA number.
     * @param {*} date
     * @param {*} dateScope
     */
    onDateChanged(date, dateScope) {
      if (some(this.changedByRANumber)) {
        this.changedByRANumber[dateScope] = false;
      } else {
        this.selectedMovementId = '';
      }
      this.queryDates[dateScope] = date.toISOString();
    },
    onSelectMovement(movementId) {
      this.selectedMovementId = movementId;
      const movement = find(this.movements, { id: movementId });
      if (movement) {
        this.changedByRANumber = {
          from: !moment(movement.startTime).isSame(this.fromDate),
          to: !moment(movement.endTime).isSame(this.toDate),
        };
        this.queryDates.from = moment.tz(movement.startTime, this.operatorTimezone).toISOString();
        this.$nextTick(() => {
          this.queryDates.to = moment.tz(movement.endTime, this.operatorTimezone).toISOString();
        });
      }
    },
    async doDownloadPdf() {
      this.isDownloading = true;
      this.$notify({
        message: 'The telemetry report export has started. This process might take a while.',
        delay: DELAY.extraLong,
        type: NOTIFICATION_TYPES.info,
      });
      const fromDateFormated = formatUtc(this.queryDates.from, DATE_FORMAT.dateTime, this.operatorTimezone).replace(' ', '_');
      const toDateFormated = formatUtc(this.queryDates.to, DATE_FORMAT.dateTime, this.operatorTimezone).replace(' ', '_');
      const filename = `telemetry_report-${this.vehicleLicensePlate}-${fromDateFormated}--${toDateFormated}`;
      await downloadPdf(TELEMETRY_PDF_ID, filename);
      this.isDownloading = false;
    },
    get,
    formatUtc,
    isEmpty,
  },
};
</script>
<template>
  <div
    class="SummaryTelemetryView"
    data-test-id="summary_telemetry-view"
  >
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="emobg-font-x-large my-4">
        Telemetry
      </h1>
      <ui-button
        :loading="isDownloading"
        :disabled="isTelemetryEmpty || !hasSelectedTelemetry"
        data-test-id="list_export-button"
        @clickbutton="doDownloadPdf"
      >
        <ui-icon
          :size="SIZES.small"
          :icon="ICONS.download"
        />
        <span class="ml-2">Export results</span>
      </ui-button>
    </div>
    <ui-card
      data-test-id="request_telemetry-card"
      header="Filter telemetry"
      class="d-block mb-4"
    >
      <div class="row no-gutters">
        <ui-select
          :value.prop="selectedMovementId"
          :options.prop="movementsOptions"
          :size="SIZES.small"
          :searchbox="{ threshold: 0 }"
          clear
          placeholder="Search for RA number"
          label="Search by RA number"
          class="col-6 pr-3"
          @selectoption="({ detail }) => onSelectMovement(detail)"
        />
        <ui-datetimepicker
          :date.prop="fromDate"
          :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
          :valid-date-range.prop="calendarDateRange"
          :size="SIZES.small"
          required
          placeholder="Select from date"
          label="Data from"
          class="DatePicker DatePicker--noBorderRight col-6 col-lg-3"
          data-test-id="from-datepicker"
          @datechanged="({ detail }) => onDateChanged(detail, DATE_SCOPES.from)"
        />
        <ui-datetimepicker
          :date.prop="toDate"
          :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
          :valid-date-range.prop="endValidDateRange"
          :size="SIZES.small"
          required
          placeholder="Select to date"
          label="Data to"
          class="DatePicker DatePicker--noBorderRadiusLeft col-6 col-lg-3"
          data-test-id="to-datepicker"
          @datechanged="({ detail }) => onDateChanged(detail, DATE_SCOPES.to)"
        />
      </div>
      <div
        v-show="!isEmpty(telemetryAvailableOptions)"
        class="row no-gutters mt-3"
      >
        <div
          v-for="option in telemetryAvailableOptions"
          :key="option"
          class="row justify-content-between align-items-center"
        >
          <ui-checkbox
            :checked="selectedTelemetry[option]"
            :caption="TELEMETRY_OPTIONS[option]"
            class="col mr-3"
            @changevalue="({ detail }) => selectedTelemetry[option] = detail"
          />
        </div>
      </div>
      <div class="row no-gutters mt-3">
        <ui-alert
          v-if="!areQueryDatesInRange"
          :color="COLORS.danger"
          :icon="ICONS.alertFull"
          data-test-id="date_validation-alert"
          class="col"
        >
          Selected period must be at most {{ TELEMETRY_SETTINGS.maxPeriod }} days long and within the last {{ TELEMETRY_SETTINGS.maxDays }} days.
        </ui-alert>
        <ui-alert
          v-if="!isLoading && isTelemetryEmpty && areQueryDatesInRange"
          :color="COLORS.danger"
          :icon="ICONS.alertFull"
          data-test-id="no_data-alert"
          class="col"
        >
          No telemetry data for period:
          {{ formatUtc(get(queryDates, DATE_SCOPES.from), DATE_FORMAT.defaultExtended, operatorTimezone) }} -
          {{ formatUtc(get(queryDates, DATE_SCOPES.to), DATE_FORMAT.defaultExtended, operatorTimezone) }}
        </ui-alert>
      </div>
    </ui-card>
    <ui-card
      v-if="!isTelemetryEmpty && hasSelectedTelemetry"
      header="Telemetry summary"
      data-test-id="summary-card"
      class="d-block mb-4"
    >
      <TelemetrySummary
        :query-dates="queryDates"
        :selected-telemetry="selectedTelemetry"
        data-test-id="telemetry_summary-component"
      />
    </ui-card>
    <TelemetryCharts :selected-telemetry="selectedTelemetry" />
    <div class="SummaryTelemetryView__wrapperPdf">
      <TelemetryPdf
        :query-dates="queryDates"
        :selected-telemetry="selectedTelemetry"
      />
    </div>
  </div>
</template>
