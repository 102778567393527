export const MOVEMENTS_ITEMS_PER_PAGE = 50;

export const LIST_HEADERS = Object.freeze({
  movementsIdentifiers: 'Movements identifiers',
  movementNumber: 'Number',
  movementType: 'Type',
  checkoutStart: 'Check-out (Start)',
  checkoutEnd: 'Check-in (End)',
  station: 'Station',
  dateTime: 'Date/time',
});

export const FILTER_KEYS = Object.freeze({
  types: 'types',
  startLocationIds: 'startLocationIds',
  endLocationIds: 'endLocationIds',
  startLocationFromDate: 'startLocationFromDate',
  startLocationToDate: 'startLocationToDate',
  endLocationFromDate: 'endLocationFromDate',
  endLocationToDate: 'endLocationToDate',
});
