var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "SummaryTripsView",
      attrs: { "data-test-id": "summary_trips-view" },
    },
    [
      _c(
        "ui-card",
        {
          staticClass: "d-block mb-4",
          attrs: {
            header: "Request trip details",
            description:
              "Specify which trip details you want to review. Trips requested cannot be older than 12 months",
            "data-test-id": "request_trip-card",
          },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm.processingReasons.length
                ? _c("ui-select", {
                    staticClass: "col-12 col-lg-6 my-2",
                    attrs: {
                      value: _vm.reason,
                      label: "Processing reason",
                      placeholder: "Select processing reason",
                      "data-test-id": "processing_reason-select",
                    },
                    domProps: { options: _vm.processingReasons },
                    on: { selectoption: ({ detail }) => (_vm.reason = detail) },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm.queryDates.from && _vm.queryDates.to && !_vm.isDateValid
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "col-6 mb-2 w-100",
                      attrs: {
                        "data-test-id": "date_validation-alert",
                        color: _vm.COLORS.danger,
                      },
                    },
                    [
                      _vm._v(
                        ' "From:" date must be before "To:" date and difference must be within 30 days '
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("ui-datetimepicker", {
                staticClass: "col-6 col-lg-3",
                attrs: {
                  "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                  "minutes-interval": 1,
                  size: _vm.SIZES.small,
                  required: "",
                  placeholder: "Select from date",
                  label: "From:",
                  "data-test-id": "from-datepicker",
                },
                domProps: { validDateRange: _vm.startValidDateRange },
                on: {
                  datechanged: ({ detail }) =>
                    _vm.updateQueryDates(
                      _vm.queryDates,
                      detail,
                      _vm.DATE_SCOPES.from
                    ),
                },
              }),
              _c("ui-datetimepicker", {
                staticClass: "col-6 col-lg-3",
                attrs: {
                  "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                  "minutes-interval": 1,
                  size: _vm.SIZES.small,
                  required: "",
                  placeholder: "Select to date",
                  label: "To:",
                  "data-test-id": "to-datepicker",
                },
                domProps: {
                  date: _vm.endMomentDate,
                  validDateRange: _vm.endValidDateRange,
                },
                on: {
                  datechanged: ({ detail }) =>
                    _vm.updateQueryDates(
                      _vm.queryDates,
                      detail,
                      _vm.DATE_SCOPES.to
                    ),
                },
              }),
              _c("ui-text-area", {
                staticClass: "col-12 mt-4",
                attrs: {
                  value: _vm.comment,
                  "data-test-id": "comment",
                  placeholder: "Describe why do you need this information",
                  label: "Comment (optional)",
                  name: "comment",
                  rows: "4",
                },
                on: { changevalue: ({ detail }) => (_vm.comment = detail) },
              }),
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-end" },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        "data-test-id": "request-button",
                        disabled:
                          !_vm.queryDates.from ||
                          !_vm.queryDates.to ||
                          !_vm.isDateValid ||
                          !_vm.reason,
                      },
                      on: { clickbutton: _vm.requestTripDetails },
                    },
                    [_vm._v(" Request details ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }