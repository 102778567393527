<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import round from 'lodash/round';

import { mapState } from 'vuex';
import {
  DATE_FORMAT,
  DISTANCE_UNIT,
  formatUtc,
  KILOMETERS_PER_MILE,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CHART_TYPES, getChartsConfig } from '../const/chart.const';
import { TELEMETRY_KEYS } from '../const/telemetry.const';
import { getChartOptions } from '../config/telemetryChart.config';
import { mileageUnitMixin } from '../../../../mixins';
import carRental from '../../../../store/CarrentalModuleMap';

import ChartComponent from '../../../../components/ChartComponent.vue';

export default {
  name: 'TelemetryCharts',
  components: {
    ChartComponent,
  },
  mixins: [mileageUnitMixin],
  props: {
    selectedTelemetry: {
      type: Object,
      default: () => ({}),
    },
    isForPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(carRental.vehicle.telemetry, {
      fuelData: state => get(state, 'telemetry.data.fuel') || [],
      stateOfChargeData: state => get(state, 'telemetry.data.stateOfCharge') || [],
      mileageData: state => get(state, 'telemetry.data.mileage') || [],
      batteryData: state => get(state, 'telemetry.data.batteryLevel') || [],
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    mileage() {
      return this.buildChartTelemetryData(this.mileageData, true);
    },
    fuel() {
      return this.buildChartTelemetryData(this.fuelData);
    },
    stateOfCharge() {
      return this.buildChartTelemetryData(this.stateOfChargeData);
    },
    battery() {
      return this.buildChartTelemetryData(this.batteryData);
    },
    cardClass() {
      return this.isForPdf ? 'noBorderCard' : 'd-block mb-4';
    },
  },
  created() {
    this.CHARTS = getChartsConfig(this.mileageUnit);
    this.CHART_TYPES = CHART_TYPES;
    this.TELEMETRY_KEYS = TELEMETRY_KEYS;
  },
  methods: {
    buildChartTelemetryData(telemetryData, isMileage) {
      /* We use dateTime format because this is the format that we'll send to apexCharts */
      return map(telemetryData, item => {
        const value = isMileage && this.mileageUnit === DISTANCE_UNIT.miles
          ? round(item.value / KILOMETERS_PER_MILE, 0)
          : item.value;
        return {
          value,
          occurredOn: formatUtc(item.occurredOn, DATE_FORMAT.dateTime, this.operatorTimezone),
        };
      });
    },
    getChartOptions,
    formatUtc,
    isEmpty,
  },
};
</script>
<template>
  <div
    class="TelemetryCharts"
    data-test-id="telemetry_charts-component"
  >
    <ui-card
      v-if="mileage.length && selectedTelemetry[TELEMETRY_KEYS.mileage]"
      :header="CHARTS.mileage.label"
      :class="cardClass"
      data-test-id="mileage-card"
    >
      <ChartComponent
        v-if="mileage.length"
        :id="`${CHARTS.mileage.id}${isForPdf ? '_pdf' : ''}`"
        :chart-options="getChartOptions(mileage, CHART_TYPES.mileage, isForPdf, mileageUnit)"
        class="w-100"
        data-test-id="mileage-chart"
      />
    </ui-card>

    <ui-card
      v-if="fuel.length && selectedTelemetry[TELEMETRY_KEYS.fuel]"
      :header="CHARTS.fuel.label"
      :class="cardClass"
      data-test-id="fuel-card"
    >
      <ChartComponent
        v-if="fuel.length"
        :id="`${CHARTS.fuel.id}${isForPdf ? '_pdf' : ''}`"
        :chart-options="getChartOptions(fuel, CHART_TYPES.fuel, isForPdf)"
        class="w-100"
        data-test-id="fuel-chart"
      />
    </ui-card>

    <ui-card
      v-if="stateOfCharge.length && selectedTelemetry[TELEMETRY_KEYS.stateOfCharge]"
      :header="CHARTS.stateOfCharge.label"
      :class="cardClass"
      data-test-id="state_of_charge-card"
    >
      <ChartComponent
        v-if="stateOfCharge.length"
        :id="`${CHARTS.stateOfCharge.id}${isForPdf ? '_pdf' : ''}`"
        :chart-options="getChartOptions(stateOfCharge, CHART_TYPES.stateOfCharge, isForPdf)"
        class="w-100"
        data-test-id="charge-chart"
      />
    </ui-card>

    <ui-card
      v-if="battery.length && selectedTelemetry[TELEMETRY_KEYS.batteryLevel]"
      :header="CHARTS.battery.label"
      :class="cardClass"
      data-test-id="battery-card"
    >
      <ChartComponent
        v-if="battery.length"
        :id="`${CHARTS.battery.id}${isForPdf ? '_pdf' : ''}`"
        :chart-options="getChartOptions(battery, CHART_TYPES.battery, isForPdf)"
        class="w-100"
        data-test-id="battery-chart"
      />
    </ui-card>
  </div>
</template>
