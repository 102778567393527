import {
  DATE_FORMAT,
  TIME_ZONE,
  formatUtc,
} from '@emobg/web-utils';

export function contentCells({ operatorTimezone = TIME_ZONE.default }) {
  return [
    {
      title: 'Status',
      width: 150,
      property: 'status',
    },
    {
      title: 'Date',
      width: 150,
      property: 'timestamp',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    }];
}

