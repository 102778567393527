var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "MovementIdentifier row" }, [
    _c("div", { staticClass: "col-6 px-3" }, [
      _vm._v(
        " " + _vm._s(_vm.movementNumber || _vm.FALLBACK_MESSAGE.dash) + " "
      ),
    ]),
    _c("div", { staticClass: "col-6 px-3" }, [
      _vm._v(" " + _vm._s(_vm.movementType || _vm.FALLBACK_MESSAGE.dash) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }