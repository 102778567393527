<script>
import get from 'lodash/get';
import map from 'lodash/map';
import isNull from 'lodash/isNull';

import { mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FILTER_KEYS } from '../const/movements.const';

export default {
  name: 'FiltersComponent',
  props: {
    isMobileLayout: {
      type: Boolean,
      default: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: [],
      startLocationIds: [],
      endLocationIds: [],
      startLocationFromDate: null,
      startLocationToDate: null,
      endLocationFromDate: null,
      endLocationToDate: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.vehicle.summary, {
      movementTypesOptions: state => map(get(state, 'movementsFilters.data.types') || [], type => ({ value: type, label: type })),
      startLocationIdsOptions: state => map(get(state, 'movementsFilters.data.startLocationIds') || [], startLocationId => ({ value: startLocationId, label: startLocationId })),
      endLocationIdsOptions: state => map(get(state, 'movementsFilters.data.endLocationIds') || [], endLocationId => ({ value: endLocationId, label: endLocationId })),
    }),
  },
  watch: {
    clear() {
      if (this.clear) {
        this.types = [];
        this.startLocationIds = [];
        this.endLocationIds = [];
        this.startLocationFromDate = null;
        this.startLocationToDate = null;
        this.endLocationFromDate = null;
        this.endLocationToDate = null;
      }
    },
  },
  created() {
    this.FILTER_KEYS = FILTER_KEYS;
  },
  methods: {
    onFilterChanged(filterData, filterKey) {
      this[filterKey] = filterData;
      this.$emit('on:filterChanged', { filterData, filterKey });
    },
    onDateChanged(date, filterKey) {
      this[filterKey] = date;
      const filterData = isNull(date) ? '' : date.toISOString();
      this.$emit('on:filterChanged', { filterData, filterKey });
    },
  },
};
</script>

<template>
  <div class="Filters w-100 col pt-3 px-1">
    <div class="row">
      <div :class="`${ isMobileLayout ? 'col-md-12 col-lg-2' : 'col-12 mb-3'}`">
        <p class="emobg-body-2 mb-1 w-100">
          Type
        </p>
        <ui-select-multiple
          :values.prop="types"
          :options.prop="movementTypesOptions"
          data-test-id="movement_type-select"
          placeholder="Select movement type"
          class="w-100"
          name="types"
          @selectoptions="({ detail }) => onFilterChanged(detail, FILTER_KEYS.types)"
        />
      </div>
      <div :class="`${ isMobileLayout ? 'col-md-12 col-lg-5' : 'col-12 mb-3'}`">
        <div class="row no-gutters">
          <div :class="`${ isMobileLayout ? 'col-4' : 'col-12 mb-3'}`">
            <p class="emobg-body-2 mb-1 w-100">
              Check-out station
            </p>
            <ui-select-multiple
              :values.prop="startLocationIds"
              :options.prop="startLocationIdsOptions"
              :class="{ 'Filters--noBorderRight': isMobileLayout }"
              data-test-id="start_station-select"
              placeholder="Station"
              class="w-100 hydrated"
              name="checkout station"
              @selectoptions="({ detail }) => onFilterChanged(detail, FILTER_KEYS.startLocationIds)"
            />
          </div>
          <div :class="`${ isMobileLayout ? 'col-4' : 'col-12 mb-3'}`">
            <p class="emobg-body-2 mb-1 w-100">
              {{ isMobileLayout ? 'From' : 'Check-out From' }}
            </p>
            <ui-datetimepicker
              :date.prop="startLocationFromDate"
              :size="SIZES.small"
              :class="{ 'Filters--noBorderRight Filters--noBorderRadiusLeft': isMobileLayout }"
              data-test-id="start_location_from-datepicker"
              class="w-100 hydrated"
              @datechanged="({ detail }) => onDateChanged(detail, FILTER_KEYS.startLocationFromDate)"
            />
          </div>
          <div :class="`${ isMobileLayout ? 'col-4' : 'col-12'}`">
            <p class="emobg-body-2 mb-1 w-100">
              {{ isMobileLayout ? 'To' : 'Check-out To' }}
            </p>
            <ui-datetimepicker
              :date.prop="startLocationToDate"
              :size="SIZES.small"
              :class="{ 'Filters--noBorderRadiusLeft': isMobileLayout }"
              data-test-id="start_location_to-datepicker"
              class="w-100 hydrated"
              @datechanged="({ detail }) => onDateChanged(detail, FILTER_KEYS.startLocationToDate)"
            />
          </div>
        </div>
      </div>
      <div :class="`${ isMobileLayout ? 'col-md-12 col-lg-5' : 'col-12'}`">
        <div class="row no-gutters">
          <div :class="`${ isMobileLayout ? 'col-4' : 'col-12 mb-3'}`">
            <p class="emobg-body-2 mb-1 w-100">
              Check-in station
            </p>
            <ui-select-multiple
              :values.prop="endLocationIds"
              :options.prop="endLocationIdsOptions"
              :class="{ 'Filters--noBorderRight': isMobileLayout }"
              data-test-id="end_station-select"
              placeholder="Station"
              class="w-100 hydrated"
              name="stations"
              @selectoptions="({ detail }) => onFilterChanged(detail, FILTER_KEYS.endLocationIds)"
            />
          </div>
          <div :class="`${ isMobileLayout ? 'col-4' : 'col-12 mb-3'}`">
            <p class="emobg-body-2 mb-1 w-100">
              {{ isMobileLayout ? 'From' : 'Check-in From' }}
            </p>
            <ui-datetimepicker
              :date.prop="endLocationFromDate"
              :size="SIZES.small"
              :class="{ 'Filters--noBorderRight Filters--noBorderRadiusLeft': isMobileLayout }"
              data-test-id="end_location_from-datepicker"
              class="w-100 hydrated"
              @datechanged="({ detail }) => onDateChanged(detail, FILTER_KEYS.endLocationFromDate)"
            />
          </div>
          <div :class="`${ isMobileLayout ? 'col-4' : 'col-12'}`">
            <p class="emobg-body-2 mb-1 w-100">
              {{ isMobileLayout ? 'To' : 'Check-in To' }}
            </p>
            <ui-datetimepicker
              :date.prop="endLocationToDate"
              :size="SIZES.small"
              :class="{ 'Filters--noBorderRadiusLeft': isMobileLayout }"
              data-test-id="end_location_to-datepicker"
              class="w-100 hydrated"
              @datechanged="({ detail }) => onDateChanged(detail, FILTER_KEYS.endLocationToDate)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
